const type = {
    toggleIsLoading: 'toogleIsLoading',
    prevStep: 'prevStep',
    nextStep: 'nextStep',
    setErrorMessage: 'setError',
    clearErrorMessage: 'clearError'
}

const initialStore = {
    isLoading: false,
    currentStep: 0,
    errorMessage: ''
}


const storeReducer = (state, action) => {
    switch (action.type) {

        case type.toggleIsLoading:
            return {
                ...state,
                isLoading: !state.isLoading
            }

        case type.prevStep:
            return {
                ...state,
                currentStep: state.currentStep - 1
            }

        case type.nextStep:
            return {
                ...state,
                currentStep: state.currentStep + 1
            }

        case type.setErrorMessage:
            return {
                ...state,
                errorMessage: action.payload
            }

        case type.clearErrorMessage:
            return {
                ...state,
                errorMessage: ''
            }

        default:
            return state
    }
}

export { initialStore, type }
export default storeReducer;