export const steps = ['Who You Are', 'Your Vision of Love', 'Who do you love?', 'Waitlist Confirmation'];

export const firstPageValidation = {
    gender: false,
    first_name: false,
    last_name: false,
    phone_number: false,
    birthday: false,
    email: false,
    linkedin: false,
    political_viewpoint: false,
    partner: false,
    headline: false,
    annual_revenue: false,
}

export const secondPageValidation = {
    activities: false,
    more_children: false,
    what_would_you_do: false
}

export const thirdPageValidation = {
    parents_relationship: false,
    confidence_level: false,
    approach_fitness: false,
    social_media: false,
    photo: false,
    headshot: false,
    mate_love_languages: false,
    mate_confidence_levels: false,
    fitness_levels: false,
    money_personality: false
}

export const fourthPageValidation = {
    waitlist: false,
    terms_and_conditions: false
}

export const spiritualPractice = [
    {
        label: 'Agnostic',
        name: 'Agnostic',
        key: 'agnostic'
    },
    {
        label: 'Atheist or Not Religious',
        name: 'Atheist or Not Religious',
        key: 'atheist_or_not_religious'
    },
    {
        label: 'Buddhist',
        name: 'Buddhist',
        key: 'buddhist'
    },
    {
        label: 'Christian or Catholic',
        name: 'Christian or Catholic',
        key: 'christian_or_catholic'
    },
    {
        label: 'God & Source Energy & The Universe',
        name: 'God & Source Energy & The Universe',
        key: 'god_and_source_energy_and_the_universe'
    },
    {
        label: 'Hindu',
        name: 'Hindu',
        key: 'hindu'
    },
    {
        label: 'Jewish',
        name: 'Jewish',
        key: 'jewish'
    },
    {
        label: 'Mindfulness & Law of Attraction',
        name: 'Mindfulness & Law of Attraction',
        key: 'mindfulness_and_law_of_attraction'
    },
    {
        label: 'Muslim',
        name: 'Muslim',
        key: 'muslim'
    },
    {
        label: 'Prefer Not to Say',
        name: 'Prefer not to say',
        key: 'prefer_not_to_say'
    },
    {
        label: 'Other',
        name: 'Other',
        key: 'option_other'
    }
];


export const partnerType = [
    {
        name:'Platonic Conversation: Nothing serious, just a friendship with a like-minded person',
        label: 'Platonic Conversation: Nothing serious, just a friendship with a like-minded person',
    },
    {
        name:'Candy on a Stick: Physical relationship with no strings attached',
        label: 'Candy on a Stick: Physical relationship with no strings attached',
    },
    {
        name:'My life partner/mate/serious relationship that could lead to marriage',
        label: 'My life partner/mate/serious relationship that could lead to marriage',
    }
]

export const loveLanguages = [
    {
        name: 'Words of Affirmation. Having affection expressed through spoken words, praise, or appreciation.',
        label: 'Words of Affirmation. Having affection expressed through spoken words, praise, or appreciation.',
        key: 'words_of_affirmation'
    },
    {
        name: 'Acts of Service. Feeling love and affection when someone does something nice for you.',
        label: 'Acts of Service. Feeling love and affection when someone does something nice for you.',
        key: 'acts_of_service'
    },
    {
        name: 'Quality Time. Love and affection are expressed when someone gives you their undivided attention.',
        label: 'Quality Time. Love and affection are expressed when someone gives you their undivided attention.',
        key: 'quality_time'
    },
    {
        name: 'Physical Touch. Love expressed through physical affection eg. hand holding, cuddling, massage etc.',
        label: 'Physical Touch. Love expressed through physical affection eg. hand holding, cuddling, massage etc.',
        key: 'physical_touch'
    },
    {
        name: 'Gifts. Receiving treasured gifts from someone that put in the time and effort.',
        label: 'Gifts. Receiving treasured gifts from someone that put in the time and effort.',
        key: 'gifts'
    }
    
]

export const activitiesType = [
    {
        name: 'Arts and Crafts',
        label: 'Arts and Crafts',
        key: 'arts_and_crafts'
    },
    {
        name: 'Cooking',
        label: 'Cooking',
        key: 'cooking'
    },
    {
        name: 'Creating and Discussing Business Enterprises',
        label: 'Creating and Discussing Business Enterprises'
    },
    {
        name: 'Gardening',
        label: 'Gardening',
        key: 'gardening'
    },
    {
        name: 'Running, Hiking, or Going To The Gym',
        label: 'Running, Hiking, or Going To The Gym',
        key: 'running_hiking_and_going_to_the_gym'
    },
    {
        name: 'Traveling',
        label: 'Traveling',
        key: 'traveling'
    },
    {
        name: 'Attending Parties, Galas and Dancing',
        label: 'Attending Parties, Galas and Dancing',
        key: 'attending_parties_galas_and_dancing'
    },
    {
        name: 'Deep Conversations',
        label: 'Deep Conversations',
        key: 'deep_conversations'
    },
    {
        name: 'Listening to Music or Going to Concerts',
        label: 'Listening to Music or Going to Concerts',
        key: 'listening_to_music_or_going_to_concerts'
    },
    {
        name: 'Exploring and Going on Adventures',
        label: 'Exploring and Going on Adventures',
        key: 'exploring_and_going_on_adventures'
    }
    ,
    {
        name: 'Gaming',
        label: 'Gaming',
        key: 'gaming'
    },
    {
        name: 'Navigating Politics',
        label: 'Navigating Politics',
        key: 'navigating_politics'
    },
    {
        name: 'Playing Sports or Attending Sporting Events',
        label: 'Playing Sports or Attending Sporting Events',
        key: 'playing_sports_or_attending_sporting_events'
    },
    {
        name: 'Investing',
        label: 'Investing',
        key: 'investing'
    },
    {
        name: 'Going to Movies or Streaming Shows',
        label: 'Going to Movies or Streaming Shows',
        key: 'going_to_movies_or_streaming_shows'
    },
    {
        name: 'Other',
        label: 'Other',
        key: 'other_option'
    }
]

export const moreChildren = [
    {
        name: 'I’m good with the number of children that I currently have..',
        label: 'I’m good with the number of children that I currently have.'
    },
    {
        name: 'I’m open to more.',
        label: 'I’m open to more.'
    },
    {
        name: 'I’m undecided if I want more children.',
        label: 'I’m undecided if I want more children.'
    },
    {
        name: 'I don’t have children of my own and I’m not open to having any.',
        label: 'I don’t have children of my own and I’m not open to having any.'
    },
    {
        name: 'I don’t have children but it’s fine if my partner has children.',
        label: 'I don’t have children but it’s fine if my partner has children.'
    }
]

export const trueLove = [
    {
        name: 'It feels like home',
        label: 'It feels like home',
        key: 'it_feels_like_home'
    },
    {
        name: 'It feels abudant',
        label: 'It feels abudant',
        key: 'it_feels_abundant'
    },
    {
        name: 'It feels uplifting',
        label: 'It feels uplifting',
        key: 'it_feels_uplifting'
    },
    {
        name: 'It feels captivating',
        label: 'It feels captivating',
        key: 'it_feels_captivating'
    },
    {
        name: 'It feels true',
        label: 'It feels true',
        key: 'it_feels_true'
    },
    {
        name: 'Other',
        label: 'Other',
        key: 'other_true_love'
    }
]

export const loveChemistryOptions = [
    {
        name: 'There are no rules to this thing, it either is or it isn’t.',
        label: 'There are no rules to this thing, it either is or it isn’t.'
    },
    {
        name: 'It is either love at first sight, instant chemistry, or none (no in between).',
        label: 'It is either love at first sight, instant chemistry, or none (no in between).'
    },
    {
        name: 'Chemistry can grow over time.',
        label: 'Chemistry can grow over time.'
    },
]

export const parentsRelationshipOptions = [
    {
        name:'Very close, we talk often.',
        label: 'Very close, we talk often.',
    },
    {
        name:'Somewhat close, we talk at least once a month.',
        label: 'Somewhat close, we talk at least once a month.',
    },
    {
        name:'Acquaintances, we are aware of each other and talk infrequently.',
        label: 'Acquaintances, we are aware of each other and talk infrequently.',
    },
    {
        name:'No relationship at all, we do not speak.',
        label: 'No relationship at all, we do not speak.',
    }
]

export const confidenceLevelOptions = [
    {
        name: 'I publicly celebrate myself with joy because I deserve it.',
        label: 'I publicly celebrate myself with joy because I deserve it.',
        key: 'i_publicly_celebrate_myself_with_joy_because_i_deserve_it.'
    },
    {
        name: 'I’m proud of who I am, but don’t feel comfortable celebrating myself.',
        label: 'I’m proud of who I am, but don’t feel comfortable celebrating myself.',
        key: 'im_proud_of_who_i_am,_but_don’t_feel_comfortable_celebrating_myself.'
    },
    {
        name: 'I’m doing OK, but I wish I was more like someone else.',
        label: 'I’m doing OK, but I wish I was more like someone else.',
        key: 'i’m_doing_OK,_but_i_wish_i_was_more_like_someone_else.'
    },
    {
        name: 'If I fix these 1-2 things in my life, I’ll be happy.',
        label: 'If I fix these 1-2 things in my life, I’ll be happy.',
        key: 'if_i_fix_these_1_2_things_in_my_life_i’ll_be_happy.'
    },
    {
        name: 'I’m working on it.',
        label: 'I’m working on it.',
        key: 'i’m_working_on_it.'
    }
]

export const approachToFitnessOptions = [
    {
        name: 'I figure out new ways to increase my fitness level for fun.',
        label: 'I figure out new ways to increase my fitness level for fun.',
        key: 'i_figure_out_new_ways_to_increase_my_fitness_level_for_fun.'
    },
    {
        name: 'I work out to maintain where I am, but have a casual approach.',
        label: 'I work out to maintain where I am, but have a casual approach.',
        key: 'i_work_out_to_maintain_where_i_am_but_have_a_casual_approach.'
    },
    {
        name: 'I work out 2-3 times per week.',
        label: 'I work out 2-3 times per week.',
        key: 'i_work_out_2_3_times_per_week.'
    },
    {
        name: 'I’ll start after I get through this next milestone.',
        label: 'I’ll start after I get through this next milestone.',
        key: 'i’ll_start_after_i_get_through_this_next_milestone.'
    },
    {
        name: 'Life’s too short to work out.',
        label: 'Life’s too short to work out.',
        key: 'life’s_too_short_to_work_out.'
    }
]


export const politicalViewpointOptions = [
    {
        name: 'Liberal',
        label: 'Liberal',
        key: 'liberal'
    },
    {
        name: 'Progressive',
        label: 'Progressive',
        key: 'progressive'
    },
    {
        name: 'Conservative',
        label: 'Conservative',
        key: 'conservative'
    },
    {
        name: 'Prefer Not to Say',
        label: 'Prefer Not to Say',
        key: 'prefer_not_to_say_option'
    },
    {
        name: 'Other',
        label: 'Other',
        key: 'other_political_viewpoint'
    },
]

export const socialMediaOptions = [
    {
        name: 'EnrichHER Newsletter',
        label: 'EnrichHER Newsletter',
        key: 'enrichher_newsletter'
    },
    {
        name: 'EnrichHER Social Media Post (Linkedin, Facebook, Instagram, Twitter)',
        label: 'EnrichHER Social Media Post (Linkedin, Facebook, Instagram, Twitter)',
        key: 'enrichher_social_media'
    },
    {
        name: 'EnrichHER Email',
        label: 'EnrichHER Email',
        key: 'enrichher_email'
    },
    {
        name: 'Word-of-mouth',
        label: 'Word-of-mouth',
        key: 'word_of_mouth'
    },
    {
        name: 'Partner Organization Affiliated with EnrichHER (HelloAlice, Women 2.0...)',
        label: 'Partner Organization Affiliated with EnrichHER (HelloAlice, Women 2.0...)',
        key: 'partner_organization_affiliated_with_enrichher'
    },
    {
        name: 'Other',
        label: 'Other',
        key:'other_source'
    }
]

export const race = [
    {
        name: 'Asian',
        label: 'Asian'
    },
    {
        name: 'African American',
        label: 'African American'
    },
    {
        name: 'Hispanic or Latino',
        label: 'Hispanic or Latino'
    },
    {
        name: 'Native American',
        label: 'Native American'
    },
    {
        name: 'Middle Eastern',
        label: 'Middle Eastern'
    },
    {
        name: 'White or Caucasian',
        label: 'White or Caucasian'
    },
    {
        name: 'Multi racial',
        label: 'Multi racial'
    },
    {
        name: 'All of the above (any race is fine)',
        label: 'All of the above (any race is fine)'
    },
]

export const confidenceLevelOptionsWoman = [
    {
        name: 'She publicly celebrates herself with joy because she deserves it.',
        label: 'She publicly celebrates herself with joy because she deserves it.',
        key: 'she_publicly_celebrates_herself_with_joy_because_she_deserves_it.'
    },
    {
        name: 'She’s proud of who she is, but doesn’t feel comfortable celebrating herself.',
        label: 'She’s proud of who she is, but doesn’t feel comfortable celebrating herself.',
        key: 'she_is_proud_of_who_she_is_but_doesnt_feel_comfortable_celebrating_herself.'
    },
    {
        name: 'She’s doing OK, but she wishes she were more like someone else.',
        label: 'She’s doing OK, but she wishes she were more like someone else.',
        key: 'she_is_doing_ok_but_she_wishes_she_were_more_like_someone_else.'
    },
    { 
        name: 'If she fix these 1-2 things in her life, she’ll be happy.',
        label: 'If she fix these 1-2 things in her life, she’ll be happy.',
        key: 'if_she_fix_these_1_2_things_in_her_life_she’ll_be_happy.'
    },
    {
        name: 'She’s working on it.',
        label: 'She’s working on it.',
        key: 'she_is_working_on_it.'
    }
]

export const confidenceLevelOptionsMan = [
    {
        name: 'He publicly celebrates himself with joy because he deserves it.',
        label: 'He publicly celebrates himself with joy because he deserves it.',
        key: 'he_publicly_celebrates_himself_with_joy_because_he_deserves_it.'
    },
    {
        name: 'He’s proud of who he is, but doesn’t feel comfortable celebrating himself.',
        label: 'He’s proud of who he is, but doesn’t feel comfortable celebrating himself.',
        key: 'he_is_proud_of_who_he_is_but_doesn’t_feel_comfortable_celebrating_himself.'
    },
    {
        name: 'He’s doing OK, but he wishes he were more like someone else.',
        label: 'He’s doing OK, but he wishes he were more like someone else.',
        key: 'he_is_doing_ok_but_he_wishes_he_were_more_like_someone_else.'
    },
    {
        name: 'If he fix these 1-2 things in his life, he’ll be happy.',
        label: 'If he fix these 1-2 things in his life, he’ll be happy.',
        key: 'if_he_fix_these_1_2_things_in_his_life_he’ll_be_happy.'
    },
    {
        name: 'He’s working on it.',
        label: 'He’s working on it.',
        key: 'he_is_working_on_it.'
    }
]

export const approachToFitnessOptionsWoman = [
    {
        name: 'She figures out new ways to increase her fitness level for fun.',
        label: 'She figures out new ways to increase her fitness level for fun.',
        key: 'she_figures_out_new_ways_to_increase_her_fitness_level_for_fun.'
    },
    {
        name: 'She works out to maintain where she is, but has a casual approach.',
        label: 'She works out to maintain where she is, but has a casual approach.',
        key: 'she_works_out_to_maintain_where_she_is_but_has_a_casual_approach.'
    },
    {
        name: 'She works out 2-3 times per week.',
        label: 'She works out 2-3 times per week.',
        key: 'she_works_out_2_3_times_per_week.'
    },
    {
        name: 'She’ll start after she gets through this next milestone.',
        label: 'She’ll start after she gets through this next milestone.',
        key: 'she’ll_start_after_she_gets_through_this_next_milestone.'
    },
    {
        name: 'Life’s too short to work out.',
        label: 'Life’s too short to work out.',
        key: 'life’s_too_short_to_work_out.'
    }
]

export const approachToFitnessOptionsMan = [
    {
        name: 'He figures out new ways to increase his fitness level for fun.',
        label: 'He figures out new ways to increase his fitness level for fun.',
        key: 'he_figures_out_new_ways_to_increase_his_fitness_level_for_fun.'
    },
    {
        name: 'He works out to maintain where he is, but has a casual approach.',
        label: 'He works out to maintain where he is, but has a casual approach.',
        key: 'He_works_out_to_maintain_where_he_is_but_has_a_casual_approach.'
    },
    {
        name: 'He works out 2-3 times per week.',
        label: 'He works out 2-3 times per week.',
        key: 'he_works_out_2_3_times_per_week.'
    },
    {
        name: 'He’ll start after he gets through this next milestone.',
        label: 'He’ll start after he gets through this next milestone.',
        key: 'he’ll_start_after_he_gets_through_this_next_milestone.'
    },
    {
        name: 'Life’s too short to work out.',
        label: 'Life’s too short to work out.',
        key: 'life’s_too_short_to_work_out.'
    }
]

export const cashQuestionOptions = [
    {
        name: 'Head to the mall or amazon and buy stuffs for myself and my loved ones.',
        label: 'Head to the mall or amazon and buy stuffs for myself and my loved ones.',
    },
    {
        name: 'Book a vacation or travel.',
        label: 'Book a vacation or travel.',
    },
    {
        name: 'Straight to my piggy bank or emergency savings.',
        label: 'Straight to my piggy bank or emergency savings.',
    },
    {
        name: 'Invest in the  market or my business.',
        label: 'Invest in the  market or my business.',
    },
    {
        name: 'Pay debt or credit card debt.',
        label: 'Pay debt or credit card debt.',
    }
]

export const moneyPersonalityOptions = [
    {
        name: 'Spender (Carpe Diem!). Someone who likes to spend money, lives in the moment, and rarely saves it for later.',
        label: 'Spender (Carpe Diem!). Someone who likes to spend money, lives in the moment, and rarely saves it for later.',
    },
    {
        name: 'Saver (Grow that Nest Egg). Someone who likes to save money for a rainy day or something special and is more prepared for those pop up expenses.',
        label: 'Saver (Grow that Nest Egg). Someone who likes to save money for a rainy day or something special and is more prepared for those pop up expenses.',
    },
    {
        name: 'Thinker (Cart Abandonment emails galore). Someone who may enjoy spending some money, but thinks about things first and is deliberate with spending.',
        label: 'Thinker (Cart Abandonment emails galore). Someone who may enjoy spending some money, but thinks about things first and is deliberate with spending.',
    }
]