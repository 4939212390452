import React from 'react';
import 'react-rangeslider/lib/index.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './Styles/css/bootstrap.min.css';
import './Styles/index.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import  ERHWithLove  from './Views/ERHWithLove';
import FormProvider from './EnrichherWithLove/stores/FormStore/FormProvider';
import ERHwithLoveProvider from './EnrichherWithLove/stores/DataStore/DataProvider';

function App() {
  return (
    <div className='App'>
      <Router>
          <FormProvider>
            <ERHwithLoveProvider>
              <Route path='/' component={ERHWithLove} />
            </ERHwithLoveProvider>
          </FormProvider>
      </Router>
    </div>
  );
}

export default App;
