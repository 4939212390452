const uploadFileToBucket = async (presignedURL, contentType, file) => {
    try {
        const response = await fetch(presignedURL, {
            method: 'put',
            headers: {
                "Content-Type": contentType
            },
            body: file
        })
        return response
    } catch (error) {
        console.log(error)
        return 'Something went wrong' 
    } 
}

export default uploadFileToBucket