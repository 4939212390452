import React from 'react'

const DragAndDrop = ({ data, dispatch, disabled, acceptedType }) => {

  const handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()
    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth + 1 })
  }

  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth - 1 })
    if (data.dropDepth > 0) return
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
  }

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'copy'
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true })
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    if (disabled) return
    if (acceptedType === '*' || e.dataTransfer.files?.[0]?.type === acceptedType) {
      let files = [...e.dataTransfer.files]
      if (files && files.length > 0) {
        const existingFiles = data.fileList.map(f => f.name)
        files = files.filter(f => !existingFiles.includes(f.name))
        dispatch({ type: 'ADD_FILE_TO_LIST', files })
        dispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 })
        dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
      }
    } else {
      dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
      dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth - 1 })
    }
  }

  return (
    <div className='drag-container'>
      <div
        className={data.inDropZone ? 'drag-drop-zone inside-drag-area' : 'drag-drop-zone'}
        onDrop={e => handleDrop(e)}
        onDragOver={e => handleDragOver(e)}
        onDragEnter={e => handleDragEnter(e)}
        onDragLeave={e => handleDragLeave(e)}
      >
        <p id='filename'> {data?.fileList?.[0]?.name ?? 'Drop files here'} </p>
      </div>
    </div>
  )
}

export default DragAndDrop