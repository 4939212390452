import React from 'react';
import Select from 'react-select';
import { Tooltip } from 'reactstrap';

export const ERHSelect = ({
                            label,
                            placeholder = 'Select option',
                            classContainer = '',
                            classLabel = '',
                            classSelect = '',
                            classNamePrefix,
                            ...rest
                          }) => {
  return (
    <div className={classContainer}>
      <label className={'label-input'} htmlFor={rest.id}>
        {label}
      </label>
      <Select placeholder={placeholder} classNamePrefix='select' className={`ERH-select ${classSelect}`} {...rest} />
    </div>
  );
};
export const ERHSelectTooltip = ({
                            label,
                            tooltip,
                            placeholder = 'Select option',
                            classContainer = '',
                            classLabel = '',
                            classSelect = '',
                            classNamePrefix,
                            ...rest
                          }) => {

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div className={classContainer}>
      <Tooltip placement="top" isOpen={tooltipOpen} target={`tooltip-${rest.id}`} toggle={toggle} >
        {tooltip}
      </Tooltip>
      <label id={`tooltip-${rest.id}`} className={'label-input'} htmlFor={rest.id}>
        {label}
      </label>
      <Select placeholder={placeholder} classNamePrefix='select' className={`ERH-select ${classSelect}`} {...rest} />
    </div>
  );
};
