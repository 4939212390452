import React, { useState, useEffect } from 'react';
import { useData, useDataDispatch } from '../stores/DataStore/DataProvider';
import { useDispatch, useStore } from '../stores/FormStore/FormProvider';
import { dataTypes } from '../stores/DataStore/DataReducer';
import { type } from '../stores/FormStore/FormReducer';
import { 
    loveLanguages,
    activitiesType,
    trueLove,
    moreChildren,
    secondPageValidation,
    cashQuestionOptions
} from '../Utils/constants';

import {
    loveLanguagesOptions,
    activitiesOptions,
    trueLoveOptions,
    cashOptions
} from '../Utils/states';

import { Col, Row, Label, FormText, Input } from 'reactstrap';
import { ERHCheckbox } from '../../Components/Checkbox';
import { ERHButton } from '../../Components/Button';
import { ERHInput } from '../../Components/Input';
import { ERHRadioBtn } from '../../Components/RadioButton';

const PageTwo = ({ setPageHeight }) => {
    const dataDispatch = useDataDispatch();
    const dispatch = useDispatch();
    const data = useData();
    const { currentStep } = useStore();
    const pageDisabled = currentStep !== 1;
    const [validInput, setValidInput] = useState({ ...secondPageValidation });
    const [otherOptionActivities, setOtherOptionActivities] = useState(false);
    const [activitiesInput, setActivitiesInput] = useState('');
    const [activitiesState, setActivitiesState] = useState({...activitiesOptions});
    
    const [loveLanguagesState, setLoveLanguagesState] = useState({...loveLanguagesOptions});
    const [cashOptionsState, setCashOptionsState] = useState({...cashOptions})
    
    const [otherOptionTrueLove, setOtherOptionTrueLove] = useState(false);
    const [trueLoveInput, setTrueLoveInput] = useState('');
    const [trueLoveState, setTrueLoveState] = useState({...trueLoveOptions});

    const requiredFields = Object.keys(validInput).filter(filterName => validInput[filterName]);
    
    

    useEffect(() => !pageDisabled && setPageHeight('page2'))

    useEffect(() => {
        const activitiesSelected = Object.keys(activitiesState).filter(filterName => activitiesState[filterName]);
        
        dataDispatch({ 
            type: dataTypes.setActivities, 
            payload: activitiesInput !== '' 
            ?
            activitiesSelected.concat(activitiesInput)
            :
            activitiesSelected
        });

        activitiesSelected.length >= 1 ? 
        setValidInput({ ...validInput, activities: true}) :
        setValidInput({ ...validInput, activities: false})

        activitiesInput !== '' ? setValidInput({ ...validInput, activities: true}) :
        activitiesSelected >= 1 && setValidInput({ ...validInput, activities: true})

    }, [activitiesState, activitiesInput]);

    useEffect(() => {
        dataDispatch({ type: dataTypes.setLoveLanguages, payload: Object.keys(loveLanguagesState).filter(filterName => loveLanguagesState[filterName])});
    }, [loveLanguagesState]);

    useEffect(() => {
        const selected = Object.keys(cashOptionsState).filter(filterName => cashOptionsState[filterName]);
        dataDispatch({ type: dataTypes.setCash, payload: selected });
        selected.length >= 1 ? 
        setValidInput({ ...validInput, what_would_you_do: true}) :
        setValidInput({ ...validInput, what_would_you_do: false})
    }, [cashOptionsState]);
    
    useEffect(() => {
        const trueLoveSelected = Object.keys(trueLoveState).filter(filterName => trueLoveState[filterName]);
        dataDispatch({ 
            type: dataTypes.setTrueLove, 
            payload: trueLoveInput !== '' 
            ?
            trueLoveSelected.concat(trueLoveInput)
            :
            trueLoveSelected
        });
    }, [trueLoveState, trueLoveInput]);

    const handleActivitiesOptions = (e) => {
        const { name, checked } = e.target;
        name !== 'Other' 
        ?
        setActivitiesState({
            ...activitiesState,
            [name]: checked
        })
        :
        setOtherOptionActivities(checked);
        name === 'Other' && !checked && setActivitiesInput('');
    }

    const handleLoveLanguagesOptions = (e) => {
        const { name, checked } = e.target;
        setLoveLanguagesState({
            ...loveLanguagesState,
            [name]: checked
        });
    }

    const handleCashOptions = (e) => {
        const { name, checked } = e.target;
        setCashOptionsState({
            ...cashOptionsState,
            [name]: checked
        });
    }

    const disabledOptionsLoveLanguages = id => {
        const limitOptions = Object.keys(loveLanguagesState).filter(filterName => loveLanguagesState[filterName]); 
        return limitOptions.length > 1 && limitOptions.indexOf(id) === -1
    };

    const disabledOptions = id => {
        const limitOptions = Object.keys(cashOptionsState).filter(filterName => cashOptionsState[filterName]); 
        return limitOptions.length > 1 && limitOptions.indexOf(id) === -1
    };

    const handleTrueLoveOptions = (e) => {
        const { name, checked } = e.target;
        name !== 'Other' 
        ?
        setTrueLoveState({
            ...trueLoveState,
            [name]: checked
        })
        :
        setOtherOptionTrueLove(checked);
        name === 'Other' && !checked && setTrueLoveInput('')
    }

    const stepCompleted = () => Object.values(validInput).every(value => value === true);

    const handleOnClick = (e) => {
        e.preventDefault();
        dispatch({ type: type.nextStep })
        
    }

    const goBack = (e) => {
        e.preventDefault()
        dispatch({ type: type.prevStep })
    }

    return (
        <div className={`fragment-form-application ${pageDisabled && 'inactive'}`}>
        <div className='form' id='page2'>
        <Row>
            <Col xs={12}>
                <Label className='label-input mb-0'>Which love languages best describe how you feel loved?</Label>
                <FormText tag='medium' className='mt-0'>Select your top 2 love languages</FormText>
                { loveLanguages.map(({name, label})=> (
                    <ERHCheckbox
                        name={name}
                        key={name}
                        label={label}
                        id={name}
                        classContainer={'my-2 px-3'}
                        styled={true}
                        disabled={disabledOptionsLoveLanguages(name)}
                        onChange={handleLoveLanguagesOptions}
                    />
                ))}
            </Col>
            <Col xs={12}>
                <Label className='mt-4 label-input mb-0'>Which types of activities do you look forward to sharing with your mate? *</Label>                     
                <FormText tag='medium' className='mt-0'>Pick as many as apply</FormText> 
            </Col>
            <Row>
                <Col xs={12} sm={6}>
                    {activitiesType.slice(0,8).map(({name, label}) => (
                        <ERHCheckbox
                            name={name}
                            key={name}
                            label={label}
                            id={name}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            onChange={handleActivitiesOptions}
                        />
                    ))}
                </Col>
                <Col xs={12} sm={6}>
                    {activitiesType.slice(8).map(({name, label, key}) => (
                        <ERHCheckbox
                            name={name}
                            key={key}
                            label={label}
                            id={key}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            onChange={handleActivitiesOptions}
                        />
                    ))}
                </Col>
            </Row>
                <Col xs={12}>
                            { otherOptionActivities && (
                                <ERHInput
                                    name='activity_input'
                                    type='text'
                                    id='activity_input'
                                    value={activitiesInput}
                                    placeholder='Enter another activity'
                                    classContainer='my-2'
                                    autoComplete='disabled'
                                    onChange={(e) => setActivitiesInput( e.target.value )}
                                    maxLength='250'
                                />
                            )}
                    </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Do you want more children? *</Label>
                <div className='d-flex flex-wrap'>
                    <div className='funding-container'>
                        {moreChildren.map(({name, label}) => (
                            <div className={`col-radio-btn`}>
                                <ERHRadioBtn
                                    name='more_children'
                                    label={label}
                                    value={label}
                                    id={name}
                                    key={name}
                                    classContainer='mr-4 my-2 funding-btn'
                                    onChange={() => {
                                        dataDispatch({ type: dataTypes.setMoreChildren, payload: name })
                                        setValidInput({...validInput, more_children: true})
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>               
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>How does true love feel to you?</Label>
                <FormText tag='medium' className='mt-0'>Pick as many as apply</FormText>                 
                {trueLove.map(({name, label, key}) => (
                    <ERHCheckbox
                        name={name}
                        key={key}
                        label={label}
                        id={key}
                        classContainer={'my-2 px-3'}
                        styled={true}
                        onChange={handleTrueLoveOptions}
                    />
                ))}
            </Col>
            <Col xs={12}>
                { otherOptionTrueLove && (
                    <ERHInput
                        name='how_true_love_feels'
                        type='text'
                        id='how_true_love_feels'
                        value={trueLoveInput}
                        placeholder='Enter your answer'
                        classContainer='my-2'
                        autoComplete='disabled'
                        onChange={(e) => setTrueLoveInput( e.target.value )}
                        maxLength='250'
                    />
                )}
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What would you do if you received $2,000 cash today?*</Label>
                <FormText tag='medium' className='mt-0'>Select up to 2 options</FormText>
                { cashQuestionOptions.map(({name, label}) => (
                    <ERHCheckbox
                        name={name}
                        key={name}
                        label={label}
                        id={name}
                        classContainer={'my-2 px-3'}
                        styled={true}
                        disabled={disabledOptions(name)}
                        onChange={handleCashOptions}
                    />
                ))}
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is your perfect day? </Label>                     
                <FormText tag='medium'>If you could do anything that you want to do, how would you plan your perfect day? Most responses are between 100 to 250 words. (This will be shared with your potential partner).</FormText>
                    <div className='px-1'>
                        <Input
                        className='mt-3 ERH-input'
                        type='textarea'
                        name="perfect_day"
                        placeholder='Describe your perfect day.'
                        value={data.perfect_day}
                        id="perfect_day"
                        maxLength='250'
                        onChange={(e) => dataDispatch({ type: dataTypes.setPerfectDay, payload: e.target.value })} 
                        />
                    </div>
            </Col>
        </Row>
        <div className='d-flex align-items-center flex-grow-1 justify-content-between my-4 flex-wrap'>
            <ERHButton
                className='my-2'
                onClick={goBack}
                disabled={false}
            >
                Go back
            </ERHButton>
            <ERHButton
                className='my-2'
                onClick={handleOnClick}
                disabled={!stepCompleted()}
            >
                Continue
            </ERHButton>
        </div>
            <p className='error-input' style={{ textAlign: 'right' }}>{requiredFields.length === 3 ? '' : 'Please complete all required fields above in order to continue'}</p>
    </div>
</div>
    )
}

export default PageTwo;
