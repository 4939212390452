import React from 'react';
import { Spinner } from 'reactstrap';
import { motion } from 'framer-motion';

const Variant = {
  enter: {
    transition: {
      duration: 1,
      ease: [0.48, 0.15, 0.25, 0.96],
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
      ease: [0.48, 0.15, 0.25, 0.96],
    },
  },
};

export const LoadingPage = ({ isLoad = false }) => {
  return isLoad && <motion.div
    key='loading'
    animate='enter'
    initial='initial'
    exit='exit'
    variants={Variant}
    className='load-page d-flex justify-content-center align-items-center'>
    <Spinner type='grow' className='spinner-application' />
  </motion.div>;
};
