const dataTypes = {
    setGender: 'setGender',
    setFirstName: 'setFirstName',
    setLastName: 'setLastName',
    setEmail: 'setEmail',
    setPhoneNumber: 'setPhoneNumber',
    setBirthday: 'setBirthday',
    setLinkedin: 'setLinkedin',
    setHeadline: 'setHeadline',
    setChildren: 'setChildren',
    setHeight: 'setHeight',
    setPoliticalViewpoint: 'setPoliticalViewpoint',
    setSpiritualPractice: 'setSpiritualPractice',
    setPartner: 'setPartner',
    setLoveLanguages: 'setLoveLanguages',
    setActivities: 'setActivities',
    setMoreChildren: 'setMoreChildren',
    setTrueLove: 'setTrueLove',
    setPerfectDay: 'setPerfectDay',
    setChemistry: 'setChemistry',
    setParentsRelationship: 'setParentsRelationship',
    setConfidenceLevel: 'setConfidenceLevel',
    setFitness: 'setFitness',
    setMateSpiritualPractice: 'setMateSpiritualPractice',
    setMatePoliticalViewpoint: 'setMatePoliticalViewpoint',
    setSocialMedia: 'setSocialMedia',
    setSocialMediaIdentified: 'setSocialMediaIdentified',
    setAnnualRevenue: 'setAnnualRevenue',
    setTermsAndConditions: 'setTermsAndConditions',
    setWailist: 'setWaitlist',
    setPhoto: 'setPhoto',
    setHeadshot: 'setHeadshot',
    setMinimumChildren: 'setMinimunChildren',
    setMaximumChildren: 'setMaximumChildren',
    setMaximumRevenue: 'setMaximumRevenue',
    setMinimumRevenue: 'setMinimumRevenue',
    setLoveLanguagesMate: 'setLoveLanguagesMate',
    setConfidenceLevelMate: 'setConfidenceLevelMate',
    setFitnessMate: 'setFitnessMate',
    setMateRace: 'setMateRace',
    setCash: 'setCash',
    setMaxHeight: 'setMaxHeight',
    setMinHeight: 'setMinHeight',
    setMoneyPersonality: 'setMoneyPersonality',
    setUTMVariables: 'setUTMVariables',
}

const initialData = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    gender: '',
    birthday: '',
    linkedin: '',
    headline: '',
    children: 2,
    height: '',
    political_view: '',
    spiritual_practice: null,
    partner: '',
    love_languages: null,
    activities: null,
    more_children: '',
    true_love_feel: null,
    perfect_day: '',
    chemistry: '',
    parents_relationship: '',
    confidence_level: '',
    approach_fitness: '',
    mate_spiritual_practice: null,
    mate_political_viewpoint: null,
    social_media: null,
    social_media_identified: [],
    annual_revenue: null,
    waitlist: false,
    terms_and_conditions: false,
    photo_url: null,
    headshot_url: null,
    maximum_children: 3,
    minimum_children: 1,
    love_languages_mate: null,
    confidence_level_mate: null,
    approach_fitness_mate: null,
    maximum_mate_revenue: 500000,
    minimum_mate_revenue: 50000,
    mate_race: null,
    cash: null,
    max_height: 6.0,
    min_height: 5.0,
    money_personality: '',
    utm_variables: null
}

const dataReducer = (state, action) => {
    switch (action.type) {

        
        case dataTypes.setGender:
            return {
                ...state,
                gender: action.payload
            }

        case dataTypes.setFirstName:
            return {
                ...state,
                first_name: action.payload
            }

        case dataTypes.setLastName:
            return {
                ...state,
                last_name: action.payload
            }

        case dataTypes.setEmail:
            return {
                ...state,
                email: action.payload
            }

        case dataTypes.setPhoneNumber:
            return {
                ...state,
                phone_number: action.payload
            }
            
            
        case dataTypes.setBirthday:
            return {
                ...state,
                birthday: action.payload
            }

        case dataTypes.setLinkedin:
            return {
                ...state,
                linkedin: action.payload
            }

        case dataTypes.setHeadline:
            return {
                ...state,
                headline: action.payload
            }

        case dataTypes.setChildren:
            return {
                ...state,
                children: action.payload
            }
               
        case dataTypes.setHeight:
            return {
                ...state,
                height: action.payload
            }

        case dataTypes.setPoliticalViewpoint:
            return {
                ...state,
                political_view: action.payload
            }

        case dataTypes.setSpiritualPractice:
            return {
                ...state,
                spiritual_practice: action.payload
            }
        case dataTypes.setPartner:
            return {
                ...state,
                partner: action.payload
            }
        case dataTypes.setLoveLanguages:
            return {
                ...state,
                love_languages: action.payload
            }
        case dataTypes.setActivities:
            return {
                ...state,
                activities: action.payload
            }
        case dataTypes.setMoreChildren:
            return {
                ...state,
                more_children: action.payload
            }

        case dataTypes.setTrueLove:
            return {
                ...state,
                true_love_feel: action.payload
            }
        case dataTypes.setPerfectDay:
            return {
                ...state,
                perfect_day: action.payload
            }
        case dataTypes.setChemistry:
            return {
                ...state,
                chemistry: action.payload
            }
        case dataTypes.setParentsRelationship:
            return {
                ...state,
                parents_relationship: action.payload
            }
        case dataTypes.setConfidenceLevel:
            return {
                ...state,
                confidence_level: action.payload
            }
        case dataTypes.setFitness:
            return {
                ...state,
                approach_fitness: action.payload
            }
        case dataTypes.setMateSpiritualPractice:
            return {
                ...state,
                mate_spiritual_practice: action.payload
            }
        case dataTypes.setMatePoliticalViewpoint:
            return {
                ...state,
                mate_political_viewpoint: action.payload
            }
        case dataTypes.setSocialMedia:
            return {
                ...state,
                social_media: action.payload
            }
        case dataTypes.setSocialMediaIdentified:
            return {
                ...state,
                social_media_identified: action.payload
            }
        case dataTypes.setAnnualRevenue:
            return {
                ...state,
                annual_revenue: action.payload
            }
        case dataTypes.setPhoto:
            return {
                ...state,
                photo_url: action.payload
            }
        case dataTypes.setTermsAndConditions:
            return {
                ...state,
                terms_and_conditions: action.payload
            }
        case dataTypes.setWailist:
            return {
                ...state,
                waitlist: action.payload
            }
        case dataTypes.setHeadshot:
            return {
                ...state,
                headshot_url: action.payload
            }
        case dataTypes.setMaximumChildren:
            return {
                ...state,
                maximum_children: action.payload
            }
        case dataTypes.setMinimumChildren:
            return {
                ...state,
                minimum_children: action.payload
            }
        case dataTypes.setMaximumRevenue:
            return {
                ...state,
                maximum_mate_revenue: action.payload
            }
        case dataTypes.setMinimumRevenue:
            return {
                ...state,
                minimum_mate_revenue: action.payload
            }
        case dataTypes.setLoveLanguagesMate:
            return {
                ...state,
                love_languages_mate: action.payload
            }
        case dataTypes.setConfidenceLevelMate:
            return {
                ...state,
                confidence_level_mate: action.payload
            }
        case dataTypes.setFitnessMate:
            return {
                ...state,
                approach_fitness_mate: action.payload
            }
        case dataTypes.setMateRace:
            return {
                ...state,
                mate_race: action.payload
            }
        case dataTypes.setCash:
            return {
                ...state,
                cash: action.payload
            }
        case dataTypes.setMaxHeight:
            return {
                ...state,
                max_height: action.payload
            }
        case dataTypes.setMinHeight:
            return {
                ...state,
                min_height: action.payload
            }
        case dataTypes.setMoneyPersonality:
            return {
                ...state,
                money_personality: action.payload
            }

        case dataTypes.setUTMVariables:
            return {
                ...state,
                utm_variables: action.payload
            }
            
        default:
            return state
    }
}

export { initialData, dataTypes }
export default dataReducer