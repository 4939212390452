import { API } from 'aws-amplify'

const getPresignedURL = async (fileName, fileType) => {
    try {
        const response = await API
            .post(
                'enrichHer-mvp',
                'getPresignedURL',
                {
                    body: {
                        fileName,
                        fileType
                    }
                }
            )
        return response
    } catch (error) {
        console.log(error)
        return 'Something went wrong'
    }
}

export default getPresignedURL
