import React, { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { LoadingPage } from '../Components/Loading';
import { DefaultLayout as Layout } from '../Layouts/DefaultLayout';
import ERHWithLoveApplication from '../EnrichherWithLove/Layouts/ERHWithLoveApplication';
import { useStore } from '../EnrichherWithLove/stores/FormStore/FormProvider';
import { useDataDispatch } from '../EnrichherWithLove/stores/DataStore/DataProvider';
import { dataTypes } from '../EnrichherWithLove/stores/DataStore/DataReducer';

const ERHWithLove = () => {
    const { isLoading } = useStore();
    const dataDispatch = useDataDispatch();

    useEffect(() => {
        let params = window.location.search.substring(1).split('&')
        let savedValue = {}
    
        for(let param of params){
        let [ name, value ] = param.split('=');
        savedValue[name] = value ? value : '';
        } 
        
        dataDispatch({ type: dataTypes.setUTMVariables, payload: savedValue});
    }, []);
    
    return (
        <>
            <AnimatePresence>
                {isLoading && <LoadingPage isLoad={isLoading} />}
            </AnimatePresence>
            <Layout className='view-application' containerFluid={false}>
                <div className='p-1'>
                    <h1 className='mb-2 mt-4'>Where does your love reside</h1>
                    <ERHWithLoveApplication />  
                </div>
            </Layout>
        </>
    )
}

export default ERHWithLove;
