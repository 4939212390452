import React, { useEffect, useReducer } from 'react'
import DragAndDrop from './DragAndDrop'
import './style.css'

const DragAndDropManager = ({ disabled, setSelectedFile, isFilePicked, setIsFilePicked, acceptedType }) => {

  const reducer = (state, action) => {
    switch (action.type) {

      case 'SET_DROP_DEPTH':
        return { ...state, dropDepth: action.dropDepth }

      case 'SET_IN_DROP_ZONE':
        return { ...state, inDropZone: action.inDropZone }

      case 'ADD_FILE_TO_LIST':
        return { ...state, fileList: action.files }

      case 'CLEAR_FILE_LIST':
        return { ...state, fileList: [] }

      default:
        return state
    }
  }

  const [data, dispatch] = useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: []
  })

  useEffect(() => {
    setSelectedFile(data?.fileList?.[0])
    setIsFilePicked(true)
  }, [data.fileList])

  useEffect(() => {
    !isFilePicked && dispatch({ type: 'CLEAR_FILE_LIST' })
  }, [isFilePicked])

  return (
    <div className="manager" >
      <DragAndDrop 
        data={data} 
        dispatch={dispatch} 
        disabled={disabled}
        acceptedType={acceptedType} 
      />
    </div>
  )
}

export default DragAndDropManager