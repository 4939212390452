import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import axios from 'axios';

import { Col, Row, Label, FormText } from 'reactstrap';
import { ERHInput } from '../../Components/Input';
import { ERHButton } from '../../Components/Button';
import { ERHRadioBtn } from '../../Components/RadioButton';
import { ERHCheckbox } from '../../Components/Checkbox';
import { ERHRangeSlider } from '../../Components/RangeSlider';
import { ERHSelect } from '../../Components/Select';

import { validateEmail, validatePhoneNumber, validateWebsite, validateDate, optionsRevenue } from '../../helpers';
import { firstPageValidation, spiritualPractice, partnerType, politicalViewpointOptions } from '../Utils/constants';
import { spiritualOptionsState } from '../Utils/states'
import { useDispatch, useStore } from '../stores/FormStore/FormProvider';
import { type } from '../stores/FormStore/FormReducer';
import { useData, useDataDispatch } from '../stores/DataStore/DataProvider';
import { dataTypes } from '../stores/DataStore/DataReducer';

const PageOne = ({ setPageHeight }) => {
    const dataDispatch = useDataDispatch();
    const dispatch = useDispatch();
    const data = useData();
    const { currentStep, errorMessage } = useStore();
    const pageDisabled = currentStep !== 0;

    const [touchedInput, setTouchedInput] = useState({ ...firstPageValidation });
    const [validInput, setValidInput] = useState({ ...firstPageValidation });
    const [otherOptionPoliticalView, setOtherOptionPoliticalView] = useState(false);
    const [otherOptionReligion, setOtherOptionReligion] = useState(false);

    const [inchesState, setInchesState] = useState('');
    const [feetState, setFeetState] = useState('')

    const [genderState, setGenderState] = useState('');
    const [politicalViewPointState, setPoliticalViewPointState] = useState('');
    const [partnerState, setPartnerState] = useState({
        platonic_partner: false,
        candy_partner: false,
        life_partner: false
    });
    const [spiritualPracticeInput, setSpiritualPracticeInput] = useState('');
    const [spiritualPracticeState, setSpiritualPracticeState] = useState({...spiritualOptionsState})

    const requiredFields = Object.keys(validInput).filter(filterName => validInput[filterName]);

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let gender = params.get('date');
        if(gender === 'man'){
            setGenderState('woman');
            setValidInput({ ...validInput, gender: true });
        }
        if(gender === 'woman'){
            setGenderState('man');
            setValidInput({ ...validInput, gender: true });
        }
    }, []);

    useEffect(() =>!pageDisabled && setPageHeight('page1'))
    
    useEffect(() => {
        dataDispatch({ type: dataTypes.setGender, payload: genderState});
    }, [genderState]);

    useEffect(() => {
        dataDispatch({ type: dataTypes.setPoliticalViewpoint, payload: politicalViewPointState});
    }, [politicalViewPointState]);

    useEffect(() => {
        dataDispatch({ type: dataTypes.setPartner, payload: partnerState });
    }, [partnerState]);

    useEffect(() => {
        const heigth = feetState && inchesState ? `${feetState}'${inchesState}` : '';
        dataDispatch({ type: dataTypes.setHeight, payload: heigth });
    }, [feetState, inchesState]);
    
    useEffect(() => {
        dataDispatch({ 
            type: dataTypes.setSpiritualPractice, 
            payload: spiritualPracticeInput !== '' 
            ? 
            Object.keys(spiritualPracticeState).filter(filterName => spiritualPracticeState[filterName]).concat(spiritualPracticeInput) 
            : 
            Object.keys(spiritualPracticeState).filter(filterName => spiritualPracticeState[filterName])});
    }, [spiritualPracticeState, spiritualPracticeInput]);

    const handlePartnerOptions = (e) => {
        const { value, checked } = e.target;
        setPartnerState(value);
        setValidInput({ ...validInput, partner: !!checked })
    }

    const handleReligionOptions = (e) => {
        const { name, checked } = e.target;
        name !== 'Other' 
        ? 
        setSpiritualPracticeState({
            ...spiritualPracticeState,
            [name]: checked
        }) 
        :
        setOtherOptionReligion(checked);
        name === 'Other' && !checked && setSpiritualPracticeInput('');
    }

    const handleSelect = (inputValue) => {
        const value = inputValue.label
        dataDispatch({ type: dataTypes.setAnnualRevenue, payload: value })
        if (value) {
            setValidInput({ ...validInput, annual_revenue: true })
        }
    }

    const handlePoliticalViewInput = (e) => {
        e.target.value != '' && setValidInput({ ...validInput, political_viewpoint: true });
        dataDispatch({ type: dataTypes.setPoliticalViewpoint, payload: e.target.value });
    }

    const stepCompleted = () => Object.values(validInput).every(value => value === true);
    
    const onBlur = (event) => {
        !touchedInput[event.target.name] && setTouchedInput({ ...touchedInput, [event.target.name]: true });
        handleValidation(event.target.name, event.target.value);
    }

    const handleValidation = (inputName, inputValue) => {
        let isValid = true;
        switch (inputName) {
            case 'email':
                isValid = !!validateEmail(inputValue);
                setValidInput({ ...validInput, [inputName]: isValid });
                break
            case 'linkedin':
                isValid = !!validateWebsite(inputValue);
                setValidInput({ ...validInput, [inputName]: isValid});
                break
            case 'birthday':
                isValid = !!validateDate(inputValue);
                setValidInput({...validInput, [inputName]: isValid });
                break
            default:
                isValid = inputValue.trim() !== ''
                setValidInput({ ...validInput, [inputName]: isValid });
        }
    }

    const handleOnClick = async (e) => {
        e.preventDefault();
        dispatch({ type: type.toggleIsLoading });
        try {
            const response = await API.post('enrichHer-mvp', 'enrichherWithLove', { 
                body: data 
            });
            if (response) {
                const URL = process.env['REACT_APP_SLACK_NOTIFICATION_SUBMIT_ZAP']
                const body = {
                    email: data.email,
                    first_name: data.first_name, 
                    last_name: data.last_name,
                    linkedin: data.linkedin,
                    phone_number: data.phone_number,
                    form: "EnrichHER with love",
                    status: 'Opt-in',
                    message: 'just completed the first step of the following quiz'
                }
                axios.post(URL, JSON.stringify({ body }));

                dispatch({ type: type.clearErrorMessage });
                dispatch({ type: type.toggleIsLoading });
                dispatch({ type: type.nextStep });
            }
        } catch {
            dispatch({ type: type.toggleIsLoading })
            dispatch({ type: type.setErrorMessage, payload: 'There was an error. Please try again.' })
        }
    }

    return (
        <div className={`fragment-form-application ${pageDisabled && 'inactive'}`}>
            <div className='form' id='page1'>
                <Row>
                    <Col xs={12}>
                    <h3 className='title-form mb-3'> Tell Us About Yourself </h3>
                    <div className={'introductory-div'}></div>
                    <Label className='label-input mb-0'>Who are you?*</Label>
                    <FormText tag='medium' className='mt-0'>What are you looking for?</FormText>
                    <div className='d-flex flex-wrap'>
                        <div className='funding-container'>
                            <div className={`col-radio-btn`}>
                                <ERHRadioBtn
                                    name='gender'
                                    id='woman'
                                    label='I am a woman looking to date a man'
                                    classContainer='mr-4 my-2 funding-btn'
                                    checked={genderState === 'woman'}
                                    onChange={() => {
                                        setGenderState('woman');
                                        setValidInput({ ...validInput, gender: true });
                                    }}
                                />
                            </div>
                            <div className={`col-radio-btn`}>
                                <ERHRadioBtn
                                    name='gender'
                                    id='man'
                                    label='I am a man looking to date a woman'
                                    classContainer='mr-4 my-2 funding-btn'
                                    checked={genderState === 'man'}
                                    onChange={() => {
                                        setGenderState('man');
                                        setValidInput({ ...validInput, gender: true });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                        </Col>
                    <Col xs={12}>
                        <Label className='label-input mb-0 mt-3'>First name *</Label>
                        <FormText tag='medium' className='mt-0'>This is not disclosed to your match until after screening is complete.</FormText>
                        <ERHInput
                            name='first_name'
                            value={data.first_name}
                            type='text'
                            placeholder='First name'
                            id='first-name'
                            classContainer='my-2'
                            autoComplete='disabled'
                            onBlur={onBlur}
                            onChange={(e) => dataDispatch({ type: dataTypes.setFirstName, payload: e.target.value })}
                            error={touchedInput.first_name && !validInput.first_name && 'Please enter a valid name.'}
                        />
                    </Col>
                    <Col xs={12}>
                        <Label className='label-input mb-0 mt-3'>Last name *</Label>
                        <ERHInput
                            name='last_name'
                            value={data.last_name}
                            type='text'
                            placeholder='Last name'
                            id='last-name'
                            classContainer='my-2'
                            autoComplete='disabled'
                            onBlur={onBlur}
                            onChange={(e) => dataDispatch({ type: dataTypes.setLastName, payload: e.target.value })}
                            error={touchedInput.last_name && !validInput.last_name && 'Please enter a valid last name.'}
                        />
                    </Col>
                    <Col xs={12}>
                        <Label className='label-input mb-0 mt-3'>Mobile phone number *</Label>
                        <FormText tag='medium' className='mt-0'>We send out text notifications as you move through the process.</FormText>
                        <ERHInput
                            name='phone_number'
                            value={data.phone_number}
                            placeholder='Enter phone number'
                            id='phone_number'
                            classContainer='my-2'
                            autoComplete='disabled'
                            onChange={(e) => dataDispatch({ type: dataTypes.setPhoneNumber, payload: e.target.value })}
                            onBlur={onBlur}
                            error={!validatePhoneNumber(data.phone_number) && data.phone_number && 'Please enter a valid phone number'}
                        />
                    </Col>
                    <Col xs={12}>
                        <Label className='label-input mb-0 mt-3'>What is your Birthdate? *</Label>
                        <FormText tag='medium' className='mt-0'>This is used to share astrological sign and age range with matches.</FormText>
                        <ERHInput
                            name='birthday'
                            value={data.birthday}
                            type='text'
                            placeholder='MM/DD/YYYY'
                            id='birthday'
                            classContainer='my-2'
                            onBlur={onBlur}
                            onChange={(e) => dataDispatch({ type: dataTypes.setBirthday, payload: e.target.value })}
                            error={touchedInput.birthday && !validInput.birthday && 'Please enter a valid date.'}
                        />
                    </Col>
                    <Col xs={12}>
                    <Label className='label-input mb-0 mt-3'>E-mail Address *</Label>
                        <ERHInput
                            name='email'
                            value={data.email}
                            type='email'
                            id='email'
                            placeholder='Email address'
                            classContainer='my-2'
                            autoComplete='disabled'
                            onBlur={onBlur}
                            onChange={(e) => dataDispatch({ type: dataTypes.setEmail, payload: e.target.value })}
                            error={touchedInput.email && !validInput.email && 'Please enter a valid email address.'}
                        />
                    </Col>
                    <Col xs={12}>
                        <Label className='label-input mb-0 mt-3'>Your Linkedin profile url *</Label>
                        <FormText tag='medium' className='mt-0'>For example: <a href='https://www.linkedin.com/in/roshawnna' target='_blank'>https://www.linkedin.com/in/roshawnna</a> </FormText>
                        <ERHInput
                            name='linkedin'
                            type='text'
                            id='linkedin'
                            placeholder='Linkedin profile'
                            value={data.linkedin}
                            required={true}
                            classContainer='my-2'
                            onBlur={onBlur}
                            onChange={(e) => dataDispatch({ type: dataTypes.setLinkedin, payload: e.target.value })}
                            error={touchedInput.linkedin && !validInput.linkedin && 'Please enter a valid URL starting with http://.'}
                        />
                    </Col>
                    <Col xs={12}>
                        <Label className='label-input mb-0 mt-3'>Headline (Shared on your dating profile)*</Label>
                        <FormText tag='medium' className='mt-0'>For example:  Fitness Enthuthiast | CEO of Fintech Company | Travel Nomad.</FormText>
                        <ERHInput
                            name='headline'
                            value={data.headline}
                            type='text'
                            id='headline'
                            placeholder='Your headline'
                            classContainer='my-2'
                            onChange={(e) => dataDispatch({ type: dataTypes.setHeadline, payload: e.target.value })}
                            onBlur={onBlur}
                            error={touchedInput.headline && !validInput.headline && 'Please enter a valid headline.'}
                        />
                    </Col>
                    <Col xs={12} sm={6} className='mt-3'>
                        <ERHRangeSlider value={data.children} id='children' name='children' min='0'
                        max='10' step='1' label='How many children do you have?'
                        classContainer={'my-2'} valueShow={data.children === '10' ? data.children + '+' : data.children} onChange={(e) => dataDispatch({ type: dataTypes.setChildren, payload: e.target.value})} />
                    </Col>
                    <Col xs={12}>
                    <Label className='label-input mb-0 mt-3'>What is your height?</Label>
                    <Row>
                        <Col xs={12} sm={3} md={3}>
                            <ERHInput
                                name='height'
                                type='text'
                                id='feet'
                                value={feetState}
                                placeholder='Feet'
                                classContainer='my-2'
                                autoComplete='disabled'
                                onChange={(e) => setFeetState(e.target.value)}
                            />
                        </Col>
                        <Col xs={12} sm={3} md={3}>
                            <ERHInput
                                name='height'
                                type='text'
                                id='inches'
                                value={inchesState}
                                placeholder='Inches'
                                classContainer='my-2'
                                autoComplete='disabled'
                                onChange={(e) => setInchesState(e.target.value)}
                            />
                        </Col>
                    </Row>
                    </Col>
                    <Col xs={12} className='mt-4'>
                        <Label className='label-input mb-0'>How much did your business earn in annual revenue last year? * </Label> 
                        <ERHSelect
                            classNamePrefix="select"
                            options={optionsRevenue}
                            id='how_much_did_your_business_earn_in_annual_revenue_last_year' 
                            name='how_much_did_your_business_earn_in_annual_revenue_last_year' 
                            name="gender"
                            onChange={handleSelect}
                        />
                    </Col>
                    <Col xs={12} >
                    <Label className='label-input mb-0 mt-3'>What is your political viewpoint?*. </Label> 
                    <FormText tag='medium' className='mb-2'>This information will not be disclosed to potential partners initially. Please be aware, it does make it more challenging to make matches without it.</FormText>                   
                    <div className='d-flex flex-wrap'>
                        <div className='funding-container'>
                        { politicalViewpointOptions.slice(0,4).map(({label, name}) => (
                            <div className={`col-radio-btn`}>
                            <ERHRadioBtn
                                name='political_viewpoint'
                                label={label}
                                value={label}
                                id={name}
                                key={name}
                                classContainer='mr-4 my-2 funding-btn'
                                checked={politicalViewPointState === name}
                                onChange={() => {
                                    setPoliticalViewPointState(name);
                                    setOtherOptionPoliticalView(false);
                                    setValidInput({ ...validInput, political_viewpoint: true })
                                }}
                            />
                            </div>
                        ))}
                            <div className={`col-radio-btn`}>
                            <ERHRadioBtn
                                name='political_viewpoint'
                                label='Other'
                                value='Other'
                                id='other'
                                classContainer='mr-4 my-2 funding-btn'
                                onChange={() => {
                                    setPoliticalViewPointState('')
                                    setOtherOptionPoliticalView(true)
                                    setValidInput({ ...validInput, political_viewpoint: false })
                                }}
                            />
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col xs={12}>
                            { otherOptionPoliticalView && (
                                <ERHInput
                                    name='political_viewpoint'
                                    type='text'
                                    id='political_viewpoint'
                                    value={data.political_view}
                                    placeholder='Enter your political viewpoint'
                                    classContainer='my-2'
                                    autoComplete='disabled'
                                    onChange={handlePoliticalViewInput}
                                    maxLength='119'
                                    onBlur={onBlur}
                                    error={touchedInput.political_viewpoint && !validInput.political_viewpoint && 'Please enter a political viewpoint.'}
                                />
                            )}
                    </Col>
                    <Col xs={12} className='mt-4'>
                        <Label className='label-input mb-0'>Which spiritual and faith practice do you identify with?</Label>
                        <FormText tag='medium' className='mt-0'>Pick as many as apply. This information will not be disclosed to potential partners initially. Please be aware, it does make it more challenging to make matches without it.</FormText>
                    </Col>
                    <Row>
                    <Col xs={12} md={6}>
                        { spiritualPractice.slice(0,6).map(({name, label})=> (
                            <>
                            <ERHCheckbox
                                name={name}
                                key={name}
                                label={label}
                                id={name}
                                classContainer={'my-2 px-3'}
                                checked={spiritualPracticeState[name]}
                                onChange={handleReligionOptions}
                                styled={true}
                            />
                            </>
                        ))}
                    </Col>
                    <Col xs={12} md={6}>
                        { spiritualPractice.slice(6).map(({name, label})=> (
                            <>
                                <ERHCheckbox
                                    name={name}
                                    key={name}
                                    label={label}
                                    id={name}
                                    classContainer={'my-2 px-3'}
                                    checked={spiritualPracticeState[name]}
                                    onChange={handleReligionOptions}
                                    styled={true}
                                />
                            </>
                        ))}
                        <Col xs={12}>
                                { otherOptionReligion && (
                                    <ERHInput
                                        name='faith_practice'
                                        type='text'
                                        id='faith_practice'
                                        value={spiritualPracticeInput}
                                        placeholder='Enter your spiritual or faith practice'
                                        classContainer='my-2'
                                        autoComplete='disabled'
                                        onChange={(e) => setSpiritualPracticeInput( e.target.value )}
                                        maxLength='250'
                                    />
                                )}
                        </Col>
                    </Col>
                    </Row>
                    <Col xs={12} className='mt-4'>
                        <Label className='label-input'>We believe that everyone should go after exactly what they want. What are you looking for in a partner? *</Label>                     
                        <div className='d-flex flex-wrap'>
                            <div className='funding-container'>
                                {partnerType.map(({name, label}) => (
                                    <div className={`col-radio-btn`}>
                                    <ERHRadioBtn
                                        name='partner_type'
                                        label={label}
                                        value={label}
                                        id={name}
                                        key={name}
                                        classContainer='mr-4 my-2 funding-btn'
                                        checked={partnerState === label}
                                        onChange={handlePartnerOptions}
                                    />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='d-flex align-items-center flex-grow-1 justify-content-between my-4 flex-wrap'>
                    <ERHButton
                        className='my-2'
                        onClick={e => {
                            e.preventDefault()
                        }}
                        outline
                    >
                        Cancel
                    </ERHButton>
                    <ERHButton
                        className='my-2'
                        onClick={handleOnClick}
                        disabled={!stepCompleted()}
                    >
                        Continue
                    </ERHButton>
                </div>
                <p className='error-input' style={{ textAlign: 'right' }}>{ errorMessage }</p>
                <p className='error-input' style={{ textAlign: 'right' }}>{requiredFields.length === 11 ? '' : 'Please complete all required fields above in order to continue'}</p>
            </div>
        </div>
    )
}

export default PageOne;
