import React, { useState, useLayoutEffect } from 'react';


const DocusignClickWrap = (props) => {
  const [mustAgree, setMustAgree] = useState(false);

  useLayoutEffect(() => {
    window.docuSignClick.Clickwrap.render({
      environment: process.env['REACT_APP_DOCUSIGN_CLICKWRAP_SDK_SOURCE'],
      accountId: process.env['REACT_APP_DOCUSIGN_ACC_ID'],
      clickwrapId: process.env['REACT_APP_DOCUSIGN_CLICKWRAP'],
      clientUserId: props.email,

      onMustAgree(agreement) {
        setMustAgree(true);
        props.accepted(false);
      },

      onAgreed(agreement) {
        props.accepted(true);
      },

      onDeclined(agreement) {
        props.accepted(false);
      }
    }, `#${props.id}`);

  }, [props.email]);

  return (
    <>
      <div id={props.id} />
      {!mustAgree && <p className='label-input mb-0 mt-2'>Terms already accepted, please continue.</p>}
    </>
  );
};

export default DocusignClickWrap;