import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { API } from 'aws-amplify';
import jwt from 'jsonwebtoken';
import { Col, Row, Label, FormText } from 'reactstrap';
import { ERHCheckbox } from '../../Components/Checkbox';
import { ERHButton } from '../../Components/Button';
import DocusignClickWrap from '../../Components/DocusignClickWrap';
import { useDispatch, useStore } from '../stores/FormStore/FormProvider';
import { useData, useDataDispatch } from '../stores/DataStore/DataProvider';
import { dataTypes } from '../stores/DataStore/DataReducer';
import { type } from '../stores/FormStore/FormReducer';
import cleanPhoneNumber from '../Utils/cleanPhoneNumber';

const PageFour = ({ setPageHeight }) => {
    const { currentStep } = useStore();
    const pageDisabled = currentStep !== 3;
    const dataDispatch = useDataDispatch();
    const dispatch = useDispatch();
    const data = useData();
    const { errorMessage } = useStore();
    const [waitlistState, setWaitlistState] = useState(false);
    const [termsAndConditionsState, setTermsAndConditionsState] = useState(false);

    const stepCompleted = () => waitlistState && termsAndConditionsState;
  
    const fromCheckout  = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('from_checkout') == 'yes';
    };

    useEffect(() =>!pageDisabled && setPageHeight('page4'))

    useEffect(() => {
        dataDispatch({ type: dataTypes.setWailist, payload: true }) 
    }, [waitlistState]);

    useEffect(() => {
        dataDispatch({ type: dataTypes.setTermsAndConditions, payload: true })
    }, [termsAndConditionsState]);

    const handleWaitlist = (e) => {
        setWaitlistState(e.target.checked);
    }

    const handleTermsAndContidions = (checked) => {
        setTermsAndConditionsState(checked);
    }

    const goBack = (e) => {
        e.preventDefault();
        dispatch({ type: type.prevStep });
    }

    const redirect = () => {
        const redirectURL = 'https://' + process.env['REACT_APP_WP_URL'] + '/nlove-dashboard/'
        if(fromCheckout()) {
            window.location.replace(redirectURL);
            return;
        }
        const payload = {
            redirectOnSuccess: redirectURL,
            email: data.email,
            firstName: data.first_name,
            phoneNumber: cleanPhoneNumber(data.phone_number),
            stripe_account: 'LE',
            product_type: 'products',
            products: [
                {
                    name: 'Waitlist fee',
                    amount: process.env['REACT_APP_APPLICATION_FEE_PRICE_ERH_WITH_LOVE'],
                    transaction_type: 'Waitlist fee',
                    description: 'Waitlist fee',
                    stripeProductId: process.env['REACT_APP_WAITLIST_FEE_ERH_WITH_LOVE_PRODUCT_ID'],
                    stripePriceId: process.env['REACT_APP_WAITLIST_FEE_ERH_WITH_LOVE_PRICE_ID']
                },
            ],
            upsellType: 'EnrichHER with Love',
            marketing_campaign: {
                utm_campaign: data.utm_campaign,
                utm_source: data.utm_source,
                utm_medium: data.utm_medium
            }
        };
        const token = jwt.sign(
            payload,
            Buffer.from(process.env.REACT_APP_JWT_PRIVATE_KEY, 'base64'),
        );
        const { REACT_APP_URL_CHECKOUT: url } = process.env;
        window.location.replace(url + token);
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        dispatch({ type: type.toggleIsLoading })
        try {
            const response = await API.post('enrichHer-mvp', 'saveEnrichherWithLoveData', {
                body: data
            })
            if (response) {
                dispatch({ type: type.toggleIsLoading })
                const URL = process.env['REACT_APP_SLACK_NOTIFICATION_SUBMIT_ZAP']
                const body = {
                    email: data.email,
                    first_name: data.first_name, 
                    last_name: data.last_name,
                    linkedin: data.linkedin,
                    phone_number: data.phone_number,
                    form: "EnrichHER with love",
                    status: 'Registration Complete',
                    message: 'just completed the following quiz'
                }
                axios.post(URL, JSON.stringify({ body }))
                redirect()
            }
        } catch {
            dispatch({ type: type.toggleIsLoading })
            dispatch({ type: type.setErrorMessage, payload: 'There was an error. Please try again.' })
        }
    }

    return (
        <div className={`fragment-form-application ${pageDisabled && 'inactive'}`}>
            <div className='form' id='page4'>
                <Row>
                  <Col xs={12} className='mt-4'>
                    <Label className='label-input'>Waitlist Fee* </Label>                     
                    <FormText tag='medium'>EnrichHER with Love is in the pre-launch phase and will launch in July of 2021. In order to collect your priority position on the waitlist, we'll collect a fee of $97 (waitlist fee only, not program cost). The full cost to participate in this program will be shared upon launch. If you do not want to proceed with the full plan, we will provide a refund of the waitlist fee. Please review our <a href='https://docs.google.com/document/d/1UDCyw0pN103qIO9JI_ELNwHKHbyv3uPtEItB1MBihDI/edit'>Refund Policy</a> for more information.</FormText>
                        <Col xs={12} className='mt-3'>
                            <ERHCheckbox 
                                name='waitlist_fee'
                                id='waitlist_fee'
                                onChange={handleWaitlist}
                                classContainer={'my-2 px-3'}
                                styled={true}
                                label='I am aware of and agree to the waitlist fee.'
                            />
                        </Col>
                  </Col>
                  <Col xs={12} className='mt-4'>
                      <Label className='label-input'>Terms & Conditions*</Label>
                      {data.email && <DocusignClickWrap email={data.email} accepted={handleTermsAndContidions} id='clickwrap' />}
                  </Col>
                </Row>
                <div className='d-flex align-items-center flex-grow-1 justify-content-between my-4 flex-wrap'>
                <ERHButton
                    className='my-2'
                    disabled={false}
                    onClick={goBack}
                >
                    Go back
                </ERHButton>
                <ERHButton
                    className='my-2'
                    onClick={onSubmit}
                    disabled={!stepCompleted()}
                >
                    Submit
                </ERHButton>
            </div>
                <p className='error-input' style={{ textAlign: 'right' }}>{errorMessage}</p>
                <p className='error-input' style={{ textAlign: 'right' }}>{stepCompleted()  ? '' : 'Please complete all required fields above in order to continue'}</p>
            </div>
        </div>
    )
}

export default PageFour
