import React, { useState, useEffect } from 'react';
import { useData, useDataDispatch } from '../stores/DataStore/DataProvider';
import { useDispatch, useStore } from '../stores/FormStore/FormProvider';
import { dataTypes } from '../stores/DataStore/DataReducer';
import { type } from '../stores/FormStore/FormReducer';
import getPresignedURL from '../Utils/getPresignedURL';
import uploadFileToBucket from '../Utils/uploadFileToBucket';

import { optionsHowDidYouHearAboutUs, formatAmount, formatHeight } from '../../helpers';
import FileUpload from '../components/Inputs/FileUpload';
import { 
    parentsRelationshipOptions,
    confidenceLevelOptions,
    approachToFitnessOptions,
    spiritualPractice,
    politicalViewpointOptions,
    socialMediaOptions,
    thirdPageValidation,
    loveLanguages,
    race,
    loveChemistryOptions,
    confidenceLevelOptionsWoman,
    confidenceLevelOptionsMan,
    approachToFitnessOptionsMan,
    approachToFitnessOptionsWoman,
    moneyPersonalityOptions
} from '../Utils/constants';

import {
    spiritualOptionsState,
    socialMediaOptionsState,
    politicalViewpointState,
    loveLanguagesOptions,
    confidenceLevelOptionsMateState,
    fitnessOptionsStateMate,
    mateRaceOptionsState
} from '../Utils/states';

import { Col, Row, Label, FormText, Input } from 'reactstrap';
import { ERHCheckbox } from '../../Components/Checkbox';
import { ERHButton } from '../../Components/Button';
import { ERHRadioBtn } from '../../Components/RadioButton';
import { ERHSelect } from '../../Components/Select';
import { ERHRangeSlider } from '../../Components/RangeSlider';
import { ERHInput } from '../../Components/Input';

const PageThree = ({ setPageHeight }) => {

    const dataDispatch = useDataDispatch();
    const dispatch = useDispatch();
    const data = useData();

    const { currentStep } = useStore();
    const pageDisabled = currentStep !== 2;
    const [validInput, setValidInput] = useState({ ...thirdPageValidation });

    const [selectedPhoto, setSelectedPhoto] = useState(false);
    const [isPhotoPicked, setIsPhotoPicked] = useState(false);
    const [photoUploaded, setPhotoUploaded] = useState(false);
    const [photoURL, setPhotoURL] = useState('');
    const [selectedHeadshot, setSelectedHeadshot] = useState(false)
    const [isHeadshotPicked, setIsHeadshotPicked] = useState(false)
    const [headshotUploaded, setHeadshotUploaded] = useState(false)
    const [headshotURL, setHeadshotURL] = useState('')

    const [spiritualPracticeState, setSpiritualPracticeState] = useState({ ...spiritualOptionsState });
    const [socialMediaState, setSocialMediaState] = useState({ ...socialMediaOptionsState})
    const [loveLanguagesState, setLoveLanguagesState] = useState({...loveLanguagesOptions});
    const [confidenceLevelMate, setConfidenceLevelMate] = useState({...confidenceLevelOptionsMateState})
    const [politicalViewState, setPoliticalViewState] = useState({...politicalViewpointState});
    const [fitnessMate, setFitnessMate] = useState({ ...fitnessOptionsStateMate });
    const [mateRace, setMateRace] = useState({ ...mateRaceOptionsState });

    const [otherOptionSpiritualPractice, setOtherOptionSpiritualPractice] = useState(false);
    const [otherOptionPoliticalView, setOtherOptionPoliticalView] = useState(false);
    const [spiritualPracticeInput, setSpiritualPracticeInput] = useState('');
    const [politicalViewInput, setPoliticalViewInput] = useState('');
    const [otherOptionErhOpportunity, setOtherOptionErhOpportunity] = useState(false);
    const [erhOpportunityInput, setErhOpportunityInput] = useState('');

    useEffect(() => {
        const handlePhotoSubmission = async () => {
            dispatch({ type: type.toggleIsLoading });
            try {
                let { name: fileName, type: fileType } = selectedPhoto
                fileName = 'erh_with_love_full_lenght_photos/' + Date.now() + fileName
                const presignedURL = await getPresignedURL(fileName, fileType);
                const { url, status } = await uploadFileToBucket(presignedURL, fileType, selectedPhoto);
                if (status === 200) setPhotoURL(url.split('?AWS')[0]);
                setPhotoUploaded(true);
            } catch {
                dispatch({ type: type.setErrorMessage, payload: 'There was an error. Please try again.' });
                setPhotoUploaded(false)
            } finally {
                dispatch({ type: type.toggleIsLoading });
            }
        }
        selectedPhoto?.name && handlePhotoSubmission();
    }, [selectedPhoto])

    useEffect(() => {
        const handleHeadshotSubmission = async () => {
            dispatch({ type: type.toggleIsLoading });
            try {
                let { name: fileName, type: fileType } = selectedHeadshot
                fileName = 'erh_with_love_headshot_photos/' + Date.now() + fileName
                const presignedURL = await getPresignedURL(fileName, fileType);
                const { url, status } = await uploadFileToBucket(presignedURL, fileType, selectedHeadshot);
                if (status === 200) setHeadshotURL(url.split('?AWS')[0]);
                setHeadshotUploaded(true);
            } catch {
                dispatch({ type: type.setErrorMessage, payload: 'There was an error. Please try again.' });
                setHeadshotUploaded(false)
            } finally {
                dispatch({ type: type.toggleIsLoading });
            }
        }
        selectedHeadshot?.name && handleHeadshotSubmission();
    }, [selectedHeadshot])

    useEffect(() =>!pageDisabled && setPageHeight('page3'));
    const requiredFields = Object.keys(validInput).filter(filterName => validInput[filterName])

    useEffect(() => {
        const spiritualPracticeSelected = Object.keys(spiritualPracticeState).filter(filterName => spiritualPracticeState[filterName]);
        dataDispatch({ 
            type: dataTypes.setMateSpiritualPractice, 
            payload: spiritualPracticeInput !== '' ?
            spiritualPracticeSelected.concat(spiritualPracticeInput) :
            spiritualPracticeSelected
        });
    }, [spiritualPracticeState, spiritualPracticeInput]);

    useEffect(() => {
        const politicalViewSelected = Object.keys(politicalViewState).filter(filterName => politicalViewState[filterName]);
        dataDispatch({ 
            type: dataTypes.setMatePoliticalViewpoint, 
            payload: politicalViewInput !== '' ?
            politicalViewSelected.concat(politicalViewInput) :
            politicalViewSelected
        });
    }, [politicalViewState, politicalViewInput]);

    useEffect(() => {
        const selected = Object.keys(confidenceLevelMate).filter(filterName => confidenceLevelMate[filterName]);
        dataDispatch({ type: dataTypes.setConfidenceLevelMate, payload: selected });
        selected.length >= 1 ? 
        setValidInput({ ...validInput, mate_confidence_levels: true}) :
        setValidInput({ ...validInput, mate_confidence_levels: false})
    }, [confidenceLevelMate]);

    useEffect(() => {
        const selected = Object.keys(fitnessMate).filter(filterName =>fitnessMate[filterName]);
        dataDispatch({ type: dataTypes.setFitnessMate, payload: selected });
        selected.length >= 1 ? 
        setValidInput({ ...validInput, fitness_levels: true}) :
        setValidInput({ ...validInput, fitness_levels: false})
    }, [fitnessMate]);

    useEffect(() => {
        dataDispatch({ type: dataTypes.setMateRace, payload: Object.keys(mateRace).filter(filterName => mateRace[filterName])});
    }, [mateRace]);

    useEffect(() => {
        const socialMediaSelected = Object.keys(socialMediaState).filter(filterName => socialMediaState[filterName]);
        dataDispatch({
            type: dataTypes.setSocialMedia, 
            payload: erhOpportunityInput !== '' ?
            socialMediaSelected.concat(erhOpportunityInput) :
            socialMediaSelected
        });

        socialMediaSelected.length >= 1 ? 
        setValidInput({ ...validInput, social_media: true}) :
        setValidInput({ ...validInput, social_media: false})

        erhOpportunityInput !== '' ? setValidInput({ ...validInput, social_media: true}) :
        socialMediaSelected >= 1 && setValidInput({ ...validInput, social_media: true})

    }, [socialMediaState, erhOpportunityInput]);

    useEffect(() => {
        dataDispatch({ type: dataTypes.setPhoto, payload: {
            file_type: 'Image',
            file_name: selectedPhoto?.name,
            file_url: photoURL,
            file_extension: selectedPhoto?.type
        }});
    }, [photoURL]);

    useEffect(() => {
        dataDispatch({ type: dataTypes.setHeadshot, payload: {
            file_type: 'Image',
            file_name: selectedHeadshot?.name,
            file_url: headshotURL,
            file_extension: selectedHeadshot?.type
        }});
    }, [headshotURL]);

    useEffect(() => {
        photoUploaded && setValidInput({ ...validInput, photo: true });
        !photoURL && setValidInput({ ...validInput, photo: false });
    }, [photoUploaded, photoURL]);

    useEffect(() => {
        headshotUploaded && setValidInput({ ...validInput, headshot: true });
        !headshotURL && setValidInput({ ...validInput, headshot: false });
    }, [headshotUploaded, headshotURL]);

    useEffect(() => {
        const selected = Object.keys(loveLanguagesState).filter(filterName => loveLanguagesState[filterName]);
        dataDispatch({ type: dataTypes.setLoveLanguagesMate, payload: selected });
        selected.length >=  1 ? 
        setValidInput({ ...validInput, mate_love_languages: true}) :
        setValidInput({ ...validInput, mate_love_languages: false})
    }, [loveLanguagesState]);

    const handleConfidenceLevelOptionsMate = (e) => {
        const { name, checked } = e.target;
        setConfidenceLevelMate({
            ...confidenceLevelMate,
            [name]: checked
        });
    }

    const handleFitnessOptionsMate = (e) => {
        const { name, checked } = e.target;
        setFitnessMate({
            ...fitnessMate,
            [name]: checked
        });
    }

    const handleMateRace = (e) => {
        const { name, checked } = e.target;
        setMateRace({
            ...mateRace,
            [name]: checked
        });
    }

    const handleSpiritualPracticeOptions = (e) => {
        const { name, checked } = e.target;
        name !== 'Other' 
        ?
        setSpiritualPracticeState({
            ...spiritualPracticeState,
            [name]: checked
        })
        :
        setOtherOptionSpiritualPractice(checked);
        name === 'Other' && !checked && setSpiritualPracticeInput('');
    }

    const handlePoliticalViewpointOptions = (e) => {
        const { name, checked } = e.target;
        name !== 'Other' 
        ?
        setPoliticalViewState({
            ...politicalViewState,
            [name]: checked
        })
        :
        setOtherOptionPoliticalView(checked);
        name === 'Other' && !checked && setPoliticalViewInput('');
    }

    const handleMediaSocialOptions = (e) => {
        const { name, checked } = e.target;
        name !== 'Other' 
        ?
        setSocialMediaState({
            ...socialMediaState,
            [name]: checked
        })
        :
        setOtherOptionErhOpportunity(checked);
        name === 'Other' && !checked && setErhOpportunityInput('');
    }

    const handleLoveLanguagesOptions = (e) => {
        const { name, checked } = e.target;
        setLoveLanguagesState({
            ...loveLanguagesState,
            [name]: checked
        });
    }

    const handleSelectContinued = (inputValue) => {
        const value = inputValue ? inputValue.map(v => v.value) : []
        dataDispatch({ type: dataTypes.setSocialMediaIdentified, payload: value })
    }

    const goBack = (e) => {
        e.preventDefault()
        dispatch({ type: type.prevStep })
    }

    const stepCompleted = () => Object.values(validInput).every(value => value === true);
    const handleOnClick = (e) => {
        e.preventDefault();
        dispatch({ type: type.nextStep })
    }

    const handleSliderChange = (e) => {
        const value = e.target.name;
        switch (value) {
            case 'maximum_mate_revenue':
                dataDispatch({ type: dataTypes.setMaximumRevenue, payload: e.target.value});
                break;
            case 'minimum_mate_revenue':
                dataDispatch({ type: dataTypes.setMinimumRevenue, payload: e.target.value});
                break;
            case 'minimum_children':
                dataDispatch({ type: dataTypes.setMinimumChildren, payload: e.target.value});
                break;
            case 'maximum_children':
                dataDispatch({ type: dataTypes.setMaximumChildren, payload: e.target.value});
                break;
            case 'min_height':
                dataDispatch({ type: dataTypes.setMinHeight, payload: e.target.value});
                break;
            case 'max_height':
                dataDispatch({ type: dataTypes.setMaxHeight, payload: e.target.value});
                break;
            default:
                break;
        }
    };
    
    return (
        <div className={`fragment-form-application ${pageDisabled && 'inactive'}`}>
        <div className='form' id='page3'>
        <Row>
            <Col xs={12}>
                <Label className='label-input'>How do you feel about love chemistry?</Label>
                <div className='d-flex flex-wrap'>
                    <div className='funding-container'>
                        { loveChemistryOptions.map(({ name, label }) => ( 
                            <div className={`col-radio-btn`}>
                                <ERHRadioBtn
                                    name='chemistry'
                                    id={name}
                                    label={label}
                                    value={label}
                                    key={name}
                                    classContainer='mr-4 my-2 funding-btn'
                                    onChange={() => dataDispatch({ type: dataTypes.setChemistry, payload: name })}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Col>
            { data.gender === 'man' ? (
                <Label className='mt-4 label-input'>What relationship do you have with your mother? *</Label> 
            ) : (
                <Label className='mt-4 label-input'>What relationship do you have with your father? *</Label> 
            )}
            <Col xs={12}>               
                    <div className='d-flex flex-wrap'>
                        <div className='funding-container'>
                        { parentsRelationshipOptions.map(({label, name}) => (
                            <div className={`col-radio-btn`}>
                                    <ERHRadioBtn
                                        name='parents_relationship'
                                        label={label}
                                        value={label}
                                        id={name}
                                        key={name}
                                        classContainer='mr-4 my-2 funding-btn'
                                        onChange={() => {
                                            dataDispatch({ type: dataTypes.setParentsRelationship, payload: name });
                                            setValidInput({...validInput, parents_relationship: true})
                                        }}
                                    />
                            </div>
                        ))}  
                        </div>
                    </div>
                </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input'>What's your confidence level? *</Label> 
                <div className='d-flex flex-wrap'>
                    <div className='funding-container'>
                        { confidenceLevelOptions.map(({label, name}) => (
                            <div className={`col-radio-btn`}>
                                <ERHRadioBtn
                                    name='confidence_level'
                                    label={label}
                                    value={label}
                                    id={name}
                                    key={name}
                                    classContainer='mr-4 my-2 funding-btn'
                                    onChange={() => {
                                        dataDispatch({ type: dataTypes.setConfidenceLevel, payload: name })
                                        setValidInput({...validInput, confidence_level: true})
                                    }}
                                />
                            </div>
                        ))}   
                    </div>
                </div>
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input'>What is your approach to fitness? *</Label> 
                <div className='d-flex flex-wrap'>
                    <div className='funding-container'>
                        { approachToFitnessOptions.map(({label, name}) => (
                            <div className={`col-radio-btn`}>
                                <ERHRadioBtn
                                    name='approach_to_fitness'
                                    label={label}
                                    value={label}
                                    id={name}
                                    key={name}
                                    classContainer='mr-4 my-2 funding-btn'
                                    onChange={() => {
                                        dataDispatch({ type: dataTypes.setFitness, payload: name })
                                        setValidInput({...validInput, approach_fitness: true})
                                    }}
                                />
                            </div>
                        ))}   
                    </div>
                </div>
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Please upload full length photo *</Label>
                <FormText tag='medium' className='description'>Please have good light and be alone in the photograph.</FormText>
                    <Col xs={12}>
                        <FileUpload
                            name='length_photo'
                            id='length_photo'
                            classContainer={'mt-4'}
                            acceptedType='*'
                            selectedFile={selectedPhoto}
                            setSelectedFile={setSelectedPhoto}
                            isFilePicked={isPhotoPicked}
                            setIsFilePicked={setIsPhotoPicked}
                            setFileURL={setPhotoURL}
                            acceptedType='*'
                        />
                    </Col>
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Please upload headshot *</Label>
                <FormText tag='medium' className='description'>Please have good light and be alone in the photograph.</FormText>
                    <Col xs={12}>
                        <FileUpload
                            name='headshot'
                            id='headshot'
                            classContainer={'mt-4'}
                            acceptedType='*'
                            selectedFile={selectedHeadshot}
                            setSelectedFile={setSelectedHeadshot}
                            isFilePicked={isHeadshotPicked}
                            setIsFilePicked={setIsHeadshotPicked}
                            setFileURL={setHeadshotURL}
                            acceptedType='*'
                        />
                    </Col>
            </Col>
            <Col xs={12} className='mt-4'>
            <h3 className='title-form my-3'> Tell Us About Your Desired Partner </h3>
            <div className={'introductory-div'}></div>
                <Label className='label-input mb-0'>Which spiritual or faith practices are you open to in your potential mate?</Label> 
                <FormText tag='medium' className='mt-0'>Select as many as are applicable to you. This information will not be disclosed to potential partners initially. Please be aware, it does make it more challenging to make matches without it.</FormText>
            </Col>
            <Row>
            <Col xs={12} md={6}>
                { spiritualPractice.slice(0,6).map(({label, name, key}) => (
                        <ERHCheckbox
                            name={name}
                            label={label}
                            id={key}
                            key={key}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            checked={spiritualPracticeState[name]}
                            onChange={handleSpiritualPracticeOptions}
                        />
                ))}   
            </Col>
            <Col xs={12} md={6}>
                { spiritualPractice.slice(6).map(({label, name, key}) => (
                        <ERHCheckbox
                            name={name}
                            label={label}
                            id={key}
                            key={key}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            checked={spiritualPracticeState[name]}
                            onChange={handleSpiritualPracticeOptions}
                        />
                ))}   
                { otherOptionSpiritualPractice && (
                    <ERHInput
                        name='spiritual_practice_mate'
                        type='text'
                        id='spiritual_practice_mate'
                        value={spiritualPracticeInput}
                        placeholder='Enter another spiritual or faith practice'
                        classContainer='my-2'
                        autoComplete='disabled'
                        onChange={(e) => setSpiritualPracticeInput( e.target.value )}
                        maxLength='250'
                    />
                )}
            </Col>
            </Row>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Which political viewpoint are you open to dating? </Label> 
                <FormText tag='medium' className='mt-0'>Select as many as are applicable. This information will not be disclosed to potential partners initially. Please be aware, it does make it more challenging to make matches without it.</FormText>
                { politicalViewpointOptions.map(({label, name, key}) => (
                        <ERHCheckbox
                            name={name}
                            label={label}
                            id={key}
                            key={key}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            onChange={handlePoliticalViewpointOptions}
                        />
                ))}
                { otherOptionPoliticalView && (
                    <ERHInput
                        name='political_viewpoint_mate'
                        type='text'
                        id='political_viewpoint_mate'
                        value={politicalViewInput}
                        placeholder='Enter another political viewpoint'
                        classContainer='my-2'
                        autoComplete='disabled'
                        onChange={(e) => setPoliticalViewInput( e.target.value )}
                        maxLength='250'
                    />
                )}
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is the minimum height and maximum height of your desired potential mate?*</Label> 
            </Col>
            <Col xs={12} sm={6}>
                <ERHRangeSlider value={data.min_height} id='min_height' name='min_height'
                min='0.0' max='7.0' step='0.1' label='Minimum Height'
                classContainer={'my-2'}
                valueShow={formatHeight(data.min_height)} onChange={handleSliderChange} />
            </Col>
            <Col xs={12} sm={6}>
                <ERHRangeSlider value={data.max_height} id='max_height' name='max_height'
                min='5.0' max='7.0' step='0.1' label='Maximum Height'
                classContainer={'my-2'}
                valueShow={formatHeight(data.max_height)} onChange={handleSliderChange} />
            </Col>
            <Col xs={12} sm={6} className='mt-3'>
                <ERHRangeSlider value={data.minimum_children} id='minimum_children' name='minimum_children' min='0'
                max='10' step='1' label='What is the minimum number of children for your potential mate?*'
                classContainer={'my-2'} valueShow={data.minimum_children} onChange={handleSliderChange}/>
            </Col>
            <Col xs={12} sm={6} className='mt-3'>
                <ERHRangeSlider value={data.maximum_children} id='maximum_children' name='maximum_children' min='0'
                max='10' step='1' label='What is the maximum number of children for your potential mate?*'
                classContainer={'my-2'} valueShow={data.maximum_children} onChange={handleSliderChange}/>
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What love languages are you open to in your potential mate?*</Label>
                <FormText tag='medium' className='mt-0'>Select as many as are applicable</FormText>
                    { loveLanguages.map(({name, label, key})=> (
                        <ERHCheckbox
                            name={name}
                            key={key}
                            label={label}
                            id={key}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            onChange={handleLoveLanguagesOptions}
                        />
                    ))}
            </Col>
            <Col className='mt-4'>
                <Label className='label-input mb-0'>Which confidence levels are you open to in your potential mate?*</Label> 
                <FormText tag='medium' className='mt-0'>Select as many as are applicable</FormText>
            </Col>
            { data.gender === 'man' ? (
                <Col xs={12}>
                    { confidenceLevelOptionsWoman.map(({label, name, key}) => (
                            <ERHCheckbox
                                name={name}
                                label={label}
                                id={key}
                                key={key}
                                styled={true}
                                classContainer={'my-2 px-3'}
                                onChange={handleConfidenceLevelOptionsMate}
                            />
                    ))}   
            </Col>
            ): (
                <Col xs={12}>
                    { confidenceLevelOptionsMan.map(({label, name, key}) => (
                            <ERHCheckbox
                                name={name}
                                label={label}
                                id={key}
                                key={key}
                                styled={true}
                                classContainer={'my-2 px-3'}
                                onChange={handleConfidenceLevelOptionsMate}
                            />
                    ))}   
            </Col>
            )}
            <Col className='mt-4'>
                <Label className='label-input mb-0'>Which fitness levels are you open to in your potential mate?*</Label> 
                <FormText tag='medium' className='mt-0'>Select as many as are applicable</FormText>
            </Col>
            { data.gender === 'man' ? (
                <Col xs={12}>
                    { approachToFitnessOptionsWoman.map(({label, name, key}) => (
                        <ERHCheckbox
                            name={name}
                            label={label}
                            id={key}
                            key={key}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            onChange={handleFitnessOptionsMate}
                        />
                    ))}   
                </Col>
            ): (
                (
                <Col xs={12}>
                    { approachToFitnessOptionsMan.map(({label, name, key}) => (
                        <ERHCheckbox
                            name={name}
                            label={label}
                            id={key}
                            key={key}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            onChange={handleFitnessOptionsMate}
                        />
                    ))}   
                </Col>
            )
            )}
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>Which race would you be open to date?</Label> 
                <FormText tag='medium' className='mt-0'>Select as many as are applicable</FormText>
                    { race.map(({label, name}) => (
                        <ERHCheckbox
                            name={name}
                            label={label}
                            id={name}
                            key={name}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            onChange={handleMateRace}
                        />
                    ))}   
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>What is the minimum and maximum revenue that your potential mate generated last year? </Label> 
            </Col>
            <Col xs={12} sm={6}>
                <ERHRangeSlider value={data.minimum_mate_revenue} id='minimum_mate_revenue' name='minimum_mate_revenue'
                min={0} max={1000000} step={10000} label='Minimum Revenue'
                classContainer={'my-2'}
                valueShow={formatAmount(data.minimum_mate_revenue) === '1,000,000.00' ? '$' + formatAmount(data.minimum_mate_revenue) + '+'  : '$' + formatAmount(data.minimum_mate_revenue)} onChange={handleSliderChange} />
            </Col>
            <Col xs={12} sm={6}>
                <ERHRangeSlider value={data.maximum_mate_revenue} id='maximum_mate_revenue' name='maximum_mate_revenue'
                min={0} max={10000000} step={10000} label='Maximum Revenue'
                classContainer={'my-2'}
                valueShow={formatAmount(data.maximum_mate_revenue) === '10,000,000.00' ? '$' + formatAmount(data.maximum_mate_revenue) + '+'  : '$' + formatAmount(data.maximum_mate_revenue)} onChange={handleSliderChange} />
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input'>Which of these is an ideal money personality you would want in your mate?*</Label>
                <div className='d-flex flex-wrap'>
                    <div className='funding-container'>
                        { moneyPersonalityOptions.map(({ name, label }) => ( 
                            <div className={`col-radio-btn`}>
                                <ERHRadioBtn
                                    name='money_personality'
                                    id={name}
                                    label={label}
                                    value={label}
                                    key={name}
                                    classContainer='mr-4 my-2 funding-btn'
                                    onChange={() => {
                                        dataDispatch({ type: dataTypes.setMoneyPersonality, payload: name })
                                        setValidInput({...validInput, money_personality: true})
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Col>
            <Col xs={12} className='mt-4'>
                <Label className='label-input mb-0'>How did you learn about EnrichHER or this opportunity? *</Label> 
                <FormText tag='medium' className='description'>Select one. For Social Media or Partner Organization, please also answer the question that follows this one.</FormText>
                { socialMediaOptions.map(({label, name, key}) => (
                        <ERHCheckbox
                            name={name}
                            label={label}
                            id={key}
                            key={key}
                            classContainer={'my-2 px-3'}
                            styled={true}
                            onChange={handleMediaSocialOptions}
                        />
                ))}   
            </Col>
            <Col xs={12}>
                { otherOptionErhOpportunity && (
                    <ERHInput
                        name='opportunity_input'
                        type='text'
                        id='opportunity_input'
                        value={erhOpportunityInput}
                        placeholder='Enter another option'
                        classContainer='my-2'
                        autoComplete='disabled'
                        onChange={(e) => setErhOpportunityInput( e.target.value )}
                        maxLength='250'
                    />
                )}
            </Col>
            <Col xs={12} className='mt-3 mb-5'>
            <Label className='label-input mb-0'>How you learned about EnrichHER continued</Label> 
                <FormText tag='medium' className='mt-0'>If you learned about us from Social Media, please identify which platform below - LinkedIn, Facebook, Instagram or Twitter. </FormText>
                <FormText className='mt-3' tag='medium'>If you learned about us from a partner organization affiliated with EnrichHER, such as Hello ALICE or Women 2.0, please identify which partner below. </FormText>
                <ERHSelect
                tooltip={'How You Learned About EnrichHER Continued *'}
                id='how_did_you_learned_about_enrichher_continued' name='how_did_you_learned_about_enrichher_continued' isMulti
                options={optionsHowDidYouHearAboutUs}
                onChange={handleSelectContinued} />
            </Col>
        </Row>
        <div className='d-flex align-items-center flex-grow-1 justify-content-between my-4 flex-wrap'>
            <ERHButton
                className='my-2'
                disabled={false}
                onClick={goBack}
            >
                Go back
            </ERHButton>
            <ERHButton
                className='my-2'
                disabled={!stepCompleted()}
                onClick={handleOnClick}
            >
                Continue
            </ERHButton>
        </div>
        <p className='error-input' style={{ textAlign: 'right' }}>{requiredFields.length === 10 ? '' : 'Please complete all required fields above in order to continue'}</p>
    </div>
</div>
    )
}

export default PageThree
