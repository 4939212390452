import React from 'react'

const AttachFile = ({ selectedFile, acceptedType, changeHandler, error }) => {
    return (
        <label class="file-upload">
            <input
                type="file"
                name="file"
                className={`ERH-input ${error ? 'error' : ''}`}
                onChange={changeHandler}
                accept={acceptedType}
                disabled={!!selectedFile}
            />
            📎 ATTACH FILE
        </label>
    )
}

export default AttachFile
