import React, { useRef, useEffect } from 'react';
import Paginate from '../../Components/Forms/FormApplication/Paginate';
import { steps } from '../Utils/constants';
import { useStore } from '../stores/FormStore/FormProvider';
import PageOne from '../Containers/PageOne';
import PageTwo from '../Containers/PageTwo';
import PageThree from '../Containers/PageThree';
import PageFour from '../Containers/PageFour';

const ERHWithLoveApplication = () => {
    const { currentStep } = useStore()
    const refWrapper = useRef()

    useEffect(() => {
        if (refWrapper) {
            document.querySelector('body').scrollTo(0, 0)
            refWrapper.current.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [currentStep])

    const setPageHeight = (pageId) => {
        const formHeight = document.getElementById(pageId).offsetHeight;
        document.getElementById('scope-form').style.height = `${formHeight + 30}px`;
    }

    return (
        <>
        <Paginate steps={steps}  currentStep={currentStep}/>
            <div ref={refWrapper} className='container-form-application'>
                <form className='my-3'>
                    <div className='wrapper-forms'>
                        <div className={'scope-form'} style={{ transform: `translate3d(${-currentStep * 100}%,0,0)` }} id='scope-form'>
                            <PageOne setPageHeight={setPageHeight}/>
                            <PageTwo setPageHeight={setPageHeight}/>
                            <PageThree setPageHeight={setPageHeight}/>
                            <PageFour setPageHeight={setPageHeight}/>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ERHWithLoveApplication;
