

export const loveLanguagesOptions = {
    words_of_affirmation: false,
    acts_of_service: false,
    quality_time: false,
    physical_touch: false,
    gifts: false
}

export const activitiesOptions = {
    arts_and_crafts: false,
    cooking: false,
    creating_and_discussing_business_enterprises: false,
    gardening: false,
    running_hiking_and_or_going_to_the_gym: false,
    traveling: false,
    attending_parties_galas_and_dancing: false,
    deep_conversations: false,
    listening_to_music_or_going_to_concerts: false,
    exploring_and_going_on_adventures: false,
    gaming: false,
    navigating_politics: false,
    playing_sports_or_attending_sporting_events: false,
    investing: false,
    going_to_movies_or_streaming_shows: false,
}

export const trueLoveOptions = {
    it_feels_like_home: false,
    it_feels_abudant: false,
    it_feels_uplifting: false,
    i_feels_captivating: false,
    it_feels_true: false
}

export const fatherOptionsState = {
    my_father_is_my_bestie_and_I_want_to_date_someone_just_like_him: false,
    grateful_but_dont_reach_out_to_him_for_advice: false,
    he_wasnt_there_for_me_as_a_child_but_i_have_gotten_passed_this: false,
    i_dont_have_a_relationship_with_my_father: false
}

export const motherOptionsState = {
    my_mother_is_my_bestie_and_I_want_to_date_someone_just_like_her: false,
    grateful_but_dont_reach_out_to_her_for_advice: false,
    she_wasnt_there_for_me_as_a_child_but_i_have_gotten_passed_this: false,
    i_dont_have_a_relationship_with_my_mother: false
}

export const confidenceLevelOptionsMateState = {
    i_publicly_celebrate_myself_with_joy_because_I_deserve_it: false,
    im_proud_of_who_i_am_but_dont_feel_comfortable_celebrating_myself: false,
    im_doing_ok_but_i_wish_i_was_more_like_someone_else: false,
    if_i_fix_these_1_2_things_in_my_life_ill_be_happy: false,
    im_working_on_it: false
}

export const fitnessOptionsStateMate = {
    i_figure_out_new_ways_to_increase_my_fitness_level_for_fun: false,
    i_work_out_to_maintain_where_i_am_but_have_a_casual_approach: false,
    i_work_out_2_3_times_per_week: false,
    ill_start_after_i_get_through_this_next_milestone: false,
    life_is_too_short_to_work_out: false
}

export const spiritualOptionsState = {
    agnostic: false,
    atheist_or_not_religious: false,
    buddhist: false,
    christian: false, 
    god_source_energy_and_the_universe: false,
    hindu: false,
    jewish: false,
    mindfulness_and_law_of_attraction: false,
    muslim: false
}

export const socialMediaOptionsState = {
    enrichher_newsletter: false,
    enrichher_social_media_post: false,
    enrichher_email: false,
    word_or_mouth: false,
    partner_organization_affiliated_with_enrichher: false,
    other: false
}

export const politicalViewpointState = {
    liberal: false,
    progressive: false,
    conservative: false,
    prefer_not_to_say: false
}

export const mateRaceOptionsState = {
    asian: false,
    african_american: false,
    hispanic_or_latino: false,
    native_american: false,
    middle_eastern: false,
    white_or_caucasian: false,
    multi_racial: false,
    all_of_the_above_any_race_is_fine: false,
}

export const cashOptions = {
    head_to_the_mall_or_amazon_and_buy_stuffs_for_myself_and_my_loved_ones: false,
    book_a_vacation_or_travel: false,
    straight_to_my_piggy_bank_or_emergency_savings: false,
    invest_in_the_market_or_my_business: false,
    pay_debt_or_credit_card_debt: false
}