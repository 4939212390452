import React from 'react'
import './style.css'

const UploadAndRemoveButtons = props => {
    const {
        showFilename,
        name,
        handleRemove
    } = props
    return (
        <>
            <div className='d-flex align-items-center flex-grow-1 flex-wrap'>
                {showFilename &&
                    <div className="filename mr-2">
                        <b>Filename:</b> {name.length > 20 ? name.substring(20, 0) + "..." : name}
                    </div>
                }
                <div
                    className="remove-btn outline"
                    onClick={handleRemove}
                >
                    REMOVE
                </div>
            </div>
            <p
                className='error-input my-2'
            >
                {name ? 'Uploaded succesfully' : 'Something went wrong'}
            </p>
        </>
    )
}

export default UploadAndRemoveButtons
