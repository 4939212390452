export function formatAmount(amount, decimalCount = 2, thousands = ',') {
  const negativeSign = amount < 0 ? '-' : '';
  let i = parseFloat(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
  var res = i.split('.');
  if (res.length == 1 || res[1].length < 3) {
    i = parseFloat(i).toFixed(decimalCount);
  }
  let j = (i.length > 3) ? i.length % 3 : 0;

  return res[0] == '0' ? parseFloat(i).toFixed(decimalCount) : negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
}

export function validateWebsite(websiteurl) {
  const websiteRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  return websiteRegex.test(websiteurl);
}

export function formatHeight(value){
  let arr = value.length >= 3 ? value.split('') : value + '.0';
  let inchees = arr.length >= 3 ? arr[2]  + '’’' : '';
  return arr.length >= 3 ? arr.slice(0,1)[0] + '’' + inchees  : arr + '’';
}

export function validatePhoneNumber(phone) {
  const phoneRegex = /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
  return phoneRegex.test(phone);
}

export const validateEmail = (email) => {
  const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return mailFormat.test(email)
}

export const validateDate = (date) => {
  const dateRegex = /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
  const today = new Date();
  const birthdate = new Date(date);
  let age = today.getFullYear() - birthdate.getFullYear();
  const month = today.getMonth() - birthdate.getMonth();

  (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) && age--;

  return dateRegex.test(date) && age >= 18;
}

export const optionsRevenue = [
  { value: 'Less than $25K', label: 'Less than $25K' },
  { value: 'Between $25K and $50K', label: 'Between $25K and $50K' },
  { value: 'Between $50K and $100K', label: 'Between $50K and $100K' },
  { value: 'Between $100K and $250K', label: 'Between $100K and $250K' },
  { value: 'Between $250K and $500K', label: 'Between $250K and $500K' },
  { value: 'Between $500K and $1 Million', label: 'Between $500K and $1 Million' },
  { value: 'More than $1 Million', label: 'More than $1 Million' }];

export const optionsSocialMediaChanels = [
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Twitter', label: 'Twitter' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Snapchat', label: 'Snapchat' },
  { value: 'TikTok', label: 'TikTok' },
  { value: 'Blog', label: 'Blog' },
  { value: 'E-mail marketing', label: 'E-mail marketing' },
  { value: 'Organic or SEO', label: 'Organic or SEO' },
  { value: 'Paid Search,', label: 'Paid Search' },
  { value: 'Content Marketing', label: 'Content Marketing' },
  { value: 'Affiliates', label: 'Affiliates' },
  { value: 'Referrals', label: 'Referrals' },
  { value: 'Direct', label: 'Direct' }];

export const optionsHowDidYouHearAboutUs = [
  { value: 'EnrichHER Website', label: 'EnrichHER Website' },
  { value: 'Hello Alice', label: 'Hello Alice' },
  { value: 'Founders of Color', label: 'Founders of Color' },
  { value: 'Biz Mujer', label: 'Biz Mujer' },
  { value: 'Black Innovation Alliance', label: 'Black Innovation Alliance' },
  { value: 'Clubhouse', label: 'Clubhouse' },
  { value: 'Center for Civic Innovation', label: 'Center for Civic Innovation' },
  { value: 'Tory Burch Foundation', label: 'Tory Burch Foundation' },
  { value: 'Intentional Life Club', label: 'Intentional Life Club' },
  { value: 'Internet', label: 'Internet' },
  { value: 'Radio', label: 'Radio' },
  { value: 'Podcast', label: 'Podcast' },
  { value: 'Social Media', label: 'Social Media' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Linkedin', label: 'Linkedin' },
  { value: 'Google', label: 'Google' },
  { value: 'Articles', label: 'Articles' },
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Events', label: 'Events' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Publication', label: 'Publication' },
  { value: 'News Article', label: 'News Article' },
  { value: 'Other', label: 'Other' }];
