//Removes any occurrence of ["+", "(", ")","-"] from the phone number
const cleanPhoneNumber = (phoneNumber) => {
    //Removes any occurrence of ["+", "(", ")","-"] from the phone number
    let formattedPhoneNumber = phoneNumber.replace(/\+|\(|\)|-/g, '')
        
    //Add USA country code ("1") if not present
    if (formattedPhoneNumber.length === 10) formattedPhoneNumber = '1' + formattedPhoneNumber
    
    return formattedPhoneNumber
}
    
export default cleanPhoneNumber