import { createContext, useContext, useReducer } from 'react';
import dataReducer, { initialData } from './DataReducer';

const DataContext = createContext();

const DataProvider = ({ children }) =>
        <DataContext.Provider value={useReducer(dataReducer, initialData)}>
            {children}
        </DataContext.Provider>

const useData = () => useContext(DataContext)[0];
const useDataDispatch = () => useContext(DataContext)[1];

export { DataContext, useData, useDataDispatch }
export default DataProvider;