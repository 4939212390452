import React, { useState } from 'react'
import DragAndDropManager from '../../Utils/DragAndDrop/DragAndDropManager'
import AttachFile from '../../Utils/Inputs/AttachFile';
import UploadAndRemoveButtons from '../../components/Inputs/UploadAndRemoveButtons'

const FileUpload = props => {
    const {
        selectedFile,
        setSelectedFile,
        isFilePicked,
        setIsFilePicked,
        classContainer,
        classLabel,
        classInput,
        error,
        label,
        restrictions,
        acceptedType,
        upload,
        setFileURL,
        ...rest
    } = props

    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [showFilename, setShowFilename] = useState(false)

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
        setShowFilename(true)
        // reset the event value to fire onChange when the same file is selected 
        event.target.value = ''
    }

    const handleRemove = (event) => {
        setSelectedFile(false)
        setIsFilePicked(false)
        setIsFileUploaded(false)
        setShowFilename(false)
        setFileURL('')
        // reset the event value to fire onChange when the same file is selected 
        event.target.value = ''
    }

    return (
        <div className={classContainer} >
            {label &&
                <label className={`label-input ${classLabel}`} htmlFor={rest.id}>
                    {label}
                </label>
            }
            {restrictions && <p className="restriction">{restrictions}</p>}

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <AttachFile
                    acceptedType={acceptedType}
                    selectedFile={!!selectedFile}
                    changeHandler={changeHandler}
                    error={error}
                />
                <DragAndDropManager
                    disabled={!!selectedFile}
                    setSelectedFile={setSelectedFile}
                    isFilePicked={isFilePicked}
                    setIsFilePicked={setIsFilePicked}
                    acceptedType={acceptedType}
                />
            </div>
            {selectedFile?.name &&
                <UploadAndRemoveButtons
                    showFilename={showFilename}
                    name={selectedFile.name}
                    handleRemove={handleRemove}
                />
            }
            {error && <p className={`error-input`}>{error}</p>}
        </div>
    )
}

export default FileUpload